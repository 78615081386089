// make pre-calculations before start to reduce calculation load per cycle
const createParallaxData = (parallaxContainerSelector, parallaxItemSelector) => {
  const parallaxContainers = Array.from(document.querySelectorAll(parallaxContainerSelector))

  // some browser apply the overall scroll to html and others to body
  const initialScrollPos = Math.max(document.documentElement.scrollTop, document.body.scrollTop)
  const viewportHeight = window.innerHeight

  const parallaxDataArray = []

  parallaxContainers.forEach(group => {
    const groupBoundingRect = group.getBoundingClientRect()
    const groupHeight = groupBoundingRect.height
    const groupTop = groupBoundingRect.top

    const parallaxItems = Array.from(group.querySelectorAll(parallaxItemSelector))

    parallaxItems.forEach(el => {
      const itemDistance = Number(el.dataset.parallaxDistance)
      if (itemDistance <= -1) return
      // determine translation speed and direction
      const translationSpeed = 1 / (1 + itemDistance) - 1

      const balancedScrollPos = initialScrollPos + groupTop + groupHeight / 2 - viewportHeight / 2

      parallaxDataArray.push({
        el,
        translationSpeed,
        balancedScrollPos,
      })
    })
  })

  return parallaxDataArray
}

// translate items
const translateItems = parallaxDataArray => {
  const scrollPos = Math.max(document.documentElement.scrollTop, document.body.scrollTop)

  parallaxDataArray.forEach(item => {
    const translateY = (item.balancedScrollPos - scrollPos) * item.translationSpeed
    item.el.style.transform = `translate3d(0,${translateY}px,0)`
  })
}

let parallaxData = [],
  then = new Date().getTime(),
  interval = 1000 / 30,
  parallaxJsRaf

const parallaxFPS = () => {
  parallaxJsRaf = window.requestAnimationFrame(parallaxFPS)

  const now = new Date().getTime()
  const delta = now - then

  if (delta > interval) {
    then = now - (delta % interval)

    translateItems(parallaxData)
  }
}

export function startParallax(settings) {
  settings = settings || {}
  settings.parallaxContainerSelector = settings.parallaxContainerSelector || '.parallax-container'
  settings.parallaxItemSelector = settings.parallaxItemSelector || '.parallax-item'
  settings.fps = settings.fps || 30

  parallaxData = createParallaxData(
    settings.parallaxContainerSelector,
    settings.parallaxItemSelector
  )

  interval = 1000 / settings.fps

  parallaxFPS()
}

export function endParallax() {
  window.cancelAnimationFrame(parallaxJsRaf)
}
