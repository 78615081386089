<template>
  <div class="language-toggle">
    <router-link
      :class="{ active: $route.params.lang == 'de' }"
      :to="{ name: $route.name, params: { lang: 'de' } }"
    >
      DE
    </router-link>
    <span class="pipe"></span>
    <router-link
      :class="{ active: $route.params.lang == 'en' }"
      :to="{ name: $route.name, params: { lang: 'en' } }"
    >
      EN
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'LanguageToggle',
}
</script>

<style scoped>
.language-toggle {
  font-family: var(--font-condensed);
  letter-spacing: 0.1em;
}

a {
  display: inline-block;
  margin-right: -0.1em;
}

a.active {
  font-weight: var(--weight-semibold);
}

.pipe {
  display: inline-block;
  height: 1.25em;
  margin: 0 0.5em;
  border-right: 1px solid var(--col-black);
  transform: translateY(0.25em);
}
</style>
