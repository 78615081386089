<template>
  <v-page>
    <main class="not-found">
      <h1 class="page-title">404</h1>

      <p>Diese Seite konnte leider nicht gefunden werden.</p>
    </main>
  </v-page>
</template>

<script>
import VPage from '@/components/Page'

export default {
  name: 'NotFound',
  components: {
    VPage,
  },
}
</script>
