var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-page',[_c('main',{staticClass:"team"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.lang == 'de' ? 'Das Team' : 'Our Team'))]),(_vm.rechtsanwaelte && _vm.rechtsanwaelte.length)?_vm._l((_vm.rechtsanwaelte),function(ref){
        var id = ref.id;
        var name = ref.name;
        var role = ref.role;
        var portrait = ref.portrait;
        var defaultBild = ref.defaultBild;
        var hoverBild = ref.hoverBild;
        var schwerpunkte = ref.schwerpunkte;
        var ausbildungen = ref.ausbildungen;
        var funktionen = ref.funktionen;
        var berufserfahrungen = ref.berufserfahrungen;
        var publikationen = ref.publikationen;
return _c('div',{key:id,staticClass:"rechtsanwalt"},[_c('div',{staticClass:"portrait"},[_c('div',{staticClass:"portrait-img"},[_c('ResponsiveImage',{staticClass:"img img--default",attrs:{"urls":defaultBild,"sizes":"(min-width: 1280px) calc((min(calc(100vw - 128px), 1280px) - 96px) / 2), (min-width: 960px) calc((max(calc(75vw + 16px), calc(100vw - 128px)) - 96px) / 2), max(max(calc(75vw + 16px), calc(100vw - 128px)), 400px)","alt":name}}),_c('ResponsiveImage',{staticClass:"img img--hover",attrs:{"urls":hoverBild,"sizes":"(min-width: 1280px) calc((min(calc(100vw - 128px), 1280px) - 96px) / 2), (min-width: 960px) calc((max(calc(75vw + 16px), calc(100vw - 128px)) - 96px) / 2), max(max(calc(75vw + 16px), calc(100vw - 128px)), 400px)","alt":name}})],1),_c('div',{staticClass:"portrait-text"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(name))]),_c('p',{staticClass:"rolle"},[_vm._v(_vm._s(role))]),_c('p',{staticClass:"portrait-text text hyphenate"},[_vm._v(_vm._s(portrait))])])]),_c('div',{staticClass:"listen"},[_c('div',{staticClass:"erfahrung"},[(ausbildungen && ausbildungen.length)?_c('div',{staticClass:"liste ausbildung"},[_c('h3',[_vm._v(_vm._s(_vm.lang == 'de' ? 'AUSBILDUNG' : 'EDUCATION'))]),_c('ul',_vm._l((ausbildungen),function(ref,i){
        var ausbildung = ref.ausbildung;
return _c('li',{key:i},[_vm._v(_vm._s(ausbildung))])}),0)]):_vm._e(),(funktionen && funktionen.length)?_c('div',{staticClass:"liste funktion"},[_c('h3',[_vm._v(_vm._s(_vm.lang == 'de' ? 'FUNKTIONEN' : 'MEMBERSHIPS'))]),_c('ul',_vm._l((funktionen),function(ref,i){
        var funktion = ref.funktion;
return _c('li',{key:i},[_vm._v(_vm._s(funktion))])}),0)]):_vm._e(),(berufserfahrungen && berufserfahrungen.length)?_c('div',{staticClass:"liste berufserfahrung"},[_c('h3',[_vm._v(_vm._s(_vm.lang == 'de' ? 'BERUFSERFAHRUNG' : 'PROFESSIONAL EXPERIENCE'))]),_c('ul',_vm._l((berufserfahrungen),function(ref,i){
        var berufserfahrung = ref.berufserfahrung;
return _c('li',{key:i},[_vm._v(" "+_vm._s(berufserfahrung)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"publikationen-link"},[(publikationen && publikationen.length)?_c('mehr-erfahren-link',{attrs:{"to":{ name: 'Publikationen', hash: ("#" + id) }}},[_vm._v(" "+_vm._s(_vm.lang == 'de' ? 'PUBLIKATIONEN' : 'PUBLICATIONS')+" ")]):_vm._e()],1)]),_c('div',{staticClass:"liste taetigkeitsschwerpunkte"},[_c('h3',[_vm._v(_vm._s(_vm.lang == 'de' ? 'TÄTIGKEITSSCHWERPUNKTE' : 'AREAS OF PRACTICE'))]),_c('ul',_vm._l((schwerpunkte),function(ref,i){
        var schwerpunkt = ref.schwerpunkt;
return _c('li',{key:i},[_vm._v(" "+_vm._s(schwerpunkt)+" ")])}),0)]),_c('div',{staticClass:"publikationen-link"},[(publikationen && publikationen.length)?_c('mehr-erfahren-link',{attrs:{"to":{ name: 'Publikationen', hash: ("#" + id) }}},[_vm._v(" "+_vm._s(_vm.lang == 'de' ? 'PUBLIKATIONEN' : 'PUBLICATIONS')+" ")]):_vm._e()],1)])])}):_vm._e(),(_vm.teammitglieder && _vm.teammitglieder.length)?[_c('div',{staticClass:"teammitglieder"},_vm._l((_vm.teammitglieder),function(ref){
        var name = ref.name;
        var role = ref.role;
        var portrait = ref.portrait;
return _c('div',{key:name,staticClass:"teammitglied"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(name))]),_c('p',{staticClass:"rolle"},[_vm._v(_vm._s(role))]),(portrait)?_c('div',{staticClass:"portrait-text text hyphenate"},[_c('p',[_vm._v(_vm._s(portrait))])]):_vm._e()])}),0)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }