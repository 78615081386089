export default [
  {
    de: {
      tage: 'Mo - Do',
      zeiten: '09:00 - 16:00 Uhr',
    },
    en: {
      tage: 'Mo - Th',
      zeiten: '9:00 am - 4:00 pm',
    },
  },
  {
    de: {
      tage: 'Fr',
      zeiten: '09:00 - 13:00 Uhr',
    },
    en: {
      tage: 'Fr',
      zeiten: '9:00 am - 1:00 pm',
    },
  },
]
