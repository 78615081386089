import { render, staticRenderFns } from "./Fachgebiet.vue?vue&type=template&id=19672b52&scoped=true&"
import script from "./Fachgebiet.vue?vue&type=script&lang=js&"
export * from "./Fachgebiet.vue?vue&type=script&lang=js&"
import style0 from "./Fachgebiet.vue?vue&type=style&index=0&id=19672b52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19672b52",
  null
  
)

export default component.exports