<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354.21 77.46">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M108.37,47.83a.78.78,0,0,1-.24-.58V1.36a.78.78,0,0,1,.24-.58.76.76,0,0,1,.57-.24h8a.78.78,0,0,1,.57.24.78.78,0,0,1,.24.58v19c0,.13,0,.21.14.23a.26.26,0,0,0,.27-.1L133.79,1a1.28,1.28,0,0,1,1-.48h9c.36,0,.59.11.68.31a.73.73,0,0,1-.14.71L129.17,20.91c-.13.18-.16.34-.07.47l16,25.73a2.46,2.46,0,0,1,.13.48c0,.32-.23.47-.68.47h-9a1,1,0,0,1-1-.54L122.59,28a.24.24,0,0,0-.21-.14.4.4,0,0,0-.27.14l-4.27,5.36a.93.93,0,0,0-.14.41V47.25a.78.78,0,0,1-.24.58.77.77,0,0,1-.57.23h-8A.75.75,0,0,1,108.37,47.83Z"
        />
        <path
          d="M193.39,47.45l-8.48-18.8a.44.44,0,0,0-.41-.27h-6.31a.3.3,0,0,0-.34.34V47.25a.78.78,0,0,1-.24.58.78.78,0,0,1-.58.23h-7.94a.78.78,0,0,1-.58-.23.78.78,0,0,1-.24-.58V1.36a.78.78,0,0,1,.24-.58.78.78,0,0,1,.58-.24H188.5a15.87,15.87,0,0,1,7.64,1.77,12.37,12.37,0,0,1,5.09,5,15.34,15.34,0,0,1,1.8,7.54,13.43,13.43,0,0,1-2.31,7.91,12.35,12.35,0,0,1-6.45,4.65.32.32,0,0,0-.2.47l9.23,19.21a1.08,1.08,0,0,1,.14.41q0,.54-.75.54h-8.35A1,1,0,0,1,193.39,47.45ZM177.85,9.1V20.71c0,.22.11.33.34.33h8.89a6.51,6.51,0,0,0,4.65-1.69,5.78,5.78,0,0,0,1.8-4.41,5.9,5.9,0,0,0-1.8-4.45,6.4,6.4,0,0,0-4.65-1.73h-8.89A.3.3,0,0,0,177.85,9.1Z"
        />
        <path
          d="M227.84,47.83a.82.82,0,0,1-.23-.58V1.36a.82.82,0,0,1,.23-.58.78.78,0,0,1,.58-.24h7.94a.78.78,0,0,1,.58.24.78.78,0,0,1,.24.58V47.25a.78.78,0,0,1-.24.58.78.78,0,0,1-.58.23h-7.94A.78.78,0,0,1,227.84,47.83Z"
        />
        <path
          d="M269.9,46.88a14,14,0,0,1-6.28-5,13,13,0,0,1-2.21-7.5v-1.5a.82.82,0,0,1,.82-.81H270a.82.82,0,0,1,.81.81v1a5.58,5.58,0,0,0,2.51,4.59,11.18,11.18,0,0,0,6.79,1.86q3.6,0,5.36-1.52a4.8,4.8,0,0,0,1.77-3.77,3.76,3.76,0,0,0-1.09-2.75,9.6,9.6,0,0,0-2.95-2C281.92,29.81,280,29,277.23,28a58.67,58.67,0,0,1-7.7-3.19,15.51,15.51,0,0,1-5.3-4.45,11.16,11.16,0,0,1-2.14-7,12.23,12.23,0,0,1,2.11-7.13A13.42,13.42,0,0,1,270,1.63,21.36,21.36,0,0,1,278.66,0a22,22,0,0,1,9.19,1.83A15,15,0,0,1,294.17,7a13.14,13.14,0,0,1,2.27,7.64v1a.78.78,0,0,1-.24.58.76.76,0,0,1-.57.24h-7.81a.78.78,0,0,1-.58-.24.78.78,0,0,1-.23-.58v-.54a6.14,6.14,0,0,0-2.35-4.85,9.52,9.52,0,0,0-6.41-2,8.15,8.15,0,0,0-5,1.36,4.43,4.43,0,0,0-1.8,3.74,4.08,4.08,0,0,0,1.05,2.85,9.19,9.19,0,0,0,3.12,2.07q2.07.91,6.42,2.48a77.09,77.09,0,0,1,7.57,3.12,15.13,15.13,0,0,1,5,4,10.59,10.59,0,0,1,2.2,7,12.17,12.17,0,0,1-4.68,10.08q-4.69,3.69-12.7,3.7A24.19,24.19,0,0,1,269.9,46.88Z"
        />
        <path
          d="M354,.78a.78.78,0,0,1,.24.58V8a.78.78,0,0,1-.24.58.76.76,0,0,1-.57.24H341.25a.3.3,0,0,0-.34.34V47.25a.78.78,0,0,1-.24.58.78.78,0,0,1-.58.23h-7.94a.78.78,0,0,1-.58-.23.78.78,0,0,1-.24-.58V9.17c0-.23-.11-.34-.33-.34H319.18a.76.76,0,0,1-.57-.24.78.78,0,0,1-.24-.58V1.36a.78.78,0,0,1,.24-.58.76.76,0,0,1,.57-.24H353.4A.76.76,0,0,1,354,.78Z"
        />
        <path
          d="M115.84,77.11l-2.88-7s0-.07-.09-.07H109.7c-.06,0-.08,0-.08.09v6.91a.2.2,0,0,1-.22.22h-.73a.19.19,0,0,1-.22-.22v-15a.19.19,0,0,1,.22-.22h4.47A3.53,3.53,0,0,1,115.87,63a4.27,4.27,0,0,1,1,3,4.32,4.32,0,0,1-.75,2.58,3.36,3.36,0,0,1-2,1.35c-.06,0-.08.07,0,.11L117,77l0,.08c0,.12-.07.18-.2.18h-.75A.25.25,0,0,1,115.84,77.11ZM109.62,63v6c0,.06,0,.09.08.09H113a2.53,2.53,0,0,0,2-.85,3.3,3.3,0,0,0,.74-2.23,3.24,3.24,0,0,0-.76-2.25,2.5,2.5,0,0,0-2-.86H109.7C109.64,62.92,109.62,63,109.62,63Z"
        />
        <path
          d="M136.15,62.92h-6.76c-.06,0-.08,0-.08.09v6c0,.06,0,.09.08.09H134a.19.19,0,0,1,.22.22v.6c0,.14-.07.21-.22.21h-4.62c-.06,0-.08,0-.08.09v6c0,.06,0,.08.08.08h6.76a.2.2,0,0,1,.22.22v.6a.2.2,0,0,1-.22.22h-7.79a.19.19,0,0,1-.22-.22v-15a.19.19,0,0,1,.22-.22h7.79a.2.2,0,0,1,.22.22v.59A.2.2,0,0,1,136.15,62.92Z"
        />
        <path
          d="M148.43,76.31a4.1,4.1,0,0,1-1.17-3V65.89a4,4,0,0,1,1.17-3,4.82,4.82,0,0,1,6.25,0,4,4,0,0,1,1.18,3v.2a.24.24,0,0,1-.07.18.2.2,0,0,1-.15.06l-.73,0a.2.2,0,0,1-.22-.23v-.3a3,3,0,0,0-.87-2.24,3.49,3.49,0,0,0-4.54,0,3,3,0,0,0-.86,2.24v7.5a3,3,0,0,0,.86,2.25,3.47,3.47,0,0,0,4.55,0,3,3,0,0,0,.86-2.25v-.26a.2.2,0,0,1,.22-.23l.73,0a.19.19,0,0,1,.22.22v.18a4,4,0,0,1-1.18,3,4.84,4.84,0,0,1-6.25,0Z"
        />
        <path
          d="M174.91,61.89h.73a.2.2,0,0,1,.22.22v15a.2.2,0,0,1-.22.22h-.73a.19.19,0,0,1-.22-.22V70.18a.08.08,0,0,0-.09-.09h-5.94c-.06,0-.08,0-.08.09v6.89c0,.15-.08.22-.23.22h-.72a.19.19,0,0,1-.22-.22v-15a.19.19,0,0,1,.22-.22h.72c.15,0,.23.07.23.22V69c0,.06,0,.09.08.09h5.94a.08.08,0,0,0,.09-.09V62.11A.19.19,0,0,1,174.91,61.89Z"
        />
        <path
          d="M195.81,62.11v.59a.19.19,0,0,1-.22.22h-3.65a.08.08,0,0,0-.09.09V77.07a.19.19,0,0,1-.22.22h-.73a.2.2,0,0,1-.22-.22V63c0-.06,0-.09-.08-.09h-3.46a.2.2,0,0,1-.22-.22v-.59a.2.2,0,0,1,.22-.22h8.45A.19.19,0,0,1,195.81,62.11Z"
        />
        <path
          d="M207.18,76.39A3.66,3.66,0,0,1,206,73.55V73a.19.19,0,0,1,.22-.22h.68a.2.2,0,0,1,.22.22v.52a2.79,2.79,0,0,0,.89,2.14,3.41,3.41,0,0,0,2.39.81,3.18,3.18,0,0,0,2.25-.76,2.88,2.88,0,0,0,.45-3.45A3.7,3.7,0,0,0,212,71.11a18.63,18.63,0,0,0-2-1.21,22.87,22.87,0,0,1-2.16-1.31,4.4,4.4,0,0,1-1.21-1.3,3.54,3.54,0,0,1-.46-1.88,3.45,3.45,0,0,1,1.09-2.72,4.24,4.24,0,0,1,2.94-1,4.46,4.46,0,0,1,3.16,1.09,3.77,3.77,0,0,1,1.17,2.9v.52a.19.19,0,0,1-.22.22h-.73a.2.2,0,0,1-.22-.22v-.48a2.86,2.86,0,0,0-.85-2.18,3.28,3.28,0,0,0-2.34-.81,3,3,0,0,0-2.1.69,2.55,2.55,0,0,0-.76,2,2.5,2.5,0,0,0,.35,1.36,3.11,3.11,0,0,0,1,1,20.85,20.85,0,0,0,2,1.17,22.7,22.7,0,0,1,2.3,1.41,4.38,4.38,0,0,1,1.27,1.35,3.56,3.56,0,0,1,.45,1.84,3.74,3.74,0,0,1-1.15,2.86,4.38,4.38,0,0,1-3.12,1.07A4.58,4.58,0,0,1,207.18,76.39Z"
        />
        <path
          d="M233,77.09l-.79-2.9a.1.1,0,0,0-.11-.07h-5.55a.11.11,0,0,0-.11.07l-.77,2.9a.23.23,0,0,1-.24.2h-.75a.19.19,0,0,1-.16-.07.17.17,0,0,1,0-.17l4.13-15a.22.22,0,0,1,.24-.18h.93a.22.22,0,0,1,.24.18l4.14,15,0,.06c0,.12-.08.18-.22.18h-.73A.23.23,0,0,1,233,77.09Zm-6.24-4a.13.13,0,0,0,.08,0h5a.11.11,0,0,0,.08,0s0,0,0-.05l-2.53-9.46s0,0-.05,0a.06.06,0,0,0-.06,0l-2.51,9.46S226.73,73.1,226.75,73.12Z"
        />
        <path
          d="M252.79,61.89h.73a.2.2,0,0,1,.22.22v15a.2.2,0,0,1-.22.22h-.8a.25.25,0,0,1-.26-.16l-6.31-12.62a.12.12,0,0,0-.08,0s0,0,0,.08V77.07a.2.2,0,0,1-.22.22h-.73a.19.19,0,0,1-.22-.22v-15a.19.19,0,0,1,.22-.22h.79a.27.27,0,0,1,.27.15l6.31,12.59s.06,0,.08,0,0,0,0-.08V62.11A.19.19,0,0,1,252.79,61.89Z"
        />
        <path
          d="M268.15,77.09l-3.57-15,0-.09c0-.1.07-.15.2-.15h.77a.23.23,0,0,1,.24.2l2.93,12.65s0,.06.05.06,0,0,.06-.06l2.66-12.65a.23.23,0,0,1,.24-.2h.73a.22.22,0,0,1,.24.2l2.75,12.65s0,.06,0,.06,0,0,0-.06l2.86-12.65a.23.23,0,0,1,.24-.2h.77a.18.18,0,0,1,.16.07.15.15,0,0,1,0,.17l-3.49,15a.23.23,0,0,1-.25.2h-.74a.23.23,0,0,1-.25-.2l-2.7-12.76s0-.07-.05-.07,0,0,0,.07l-2.68,12.76a.22.22,0,0,1-.25.2h-.72A.23.23,0,0,1,268.15,77.09Z"
        />
        <path
          d="M296,77.09l-.79-2.9s-.05-.07-.11-.07h-5.54c-.06,0-.1,0-.11.07l-.77,2.9a.23.23,0,0,1-.24.2h-.75a.19.19,0,0,1-.17-.07.23.23,0,0,1,0-.17l4.14-15a.22.22,0,0,1,.24-.18h.92a.22.22,0,0,1,.24.18l4.14,15,0,.06c0,.12-.07.18-.22.18h-.72A.23.23,0,0,1,296,77.09Zm-6.16-17.75a1.17,1.17,0,1,1,.8.31A1.1,1.1,0,0,1,289.79,59.34Zm-.07,13.78a.09.09,0,0,0,.07,0h5a.09.09,0,0,0,.07,0s0,0,0-.05l-2.53-9.46s0,0-.05,0,0,0-.06,0l-2.5,9.46S289.7,73.1,289.72,73.12Zm3.44-13.78a1.17,1.17,0,0,1,0-1.59,1.09,1.09,0,0,1,.79-.3,1.08,1.08,0,0,1,.79.3,1.17,1.17,0,0,1,0,1.59,1.09,1.09,0,0,1-.79.31A1.1,1.1,0,0,1,293.16,59.34Z"
        />
        <path
          d="M307.86,77.07v-15a.19.19,0,0,1,.22-.22h.72a.19.19,0,0,1,.22.22V76.17c0,.06,0,.08.09.08h6.51a.2.2,0,0,1,.22.22v.6a.19.19,0,0,1-.22.22h-7.54A.19.19,0,0,1,307.86,77.07Z"
        />
        <path
          d="M332.67,62.11v.59a.19.19,0,0,1-.22.22H328.8a.08.08,0,0,0-.09.09V77.07a.19.19,0,0,1-.22.22h-.72a.19.19,0,0,1-.22-.22V63a.08.08,0,0,0-.09-.09H324a.2.2,0,0,1-.22-.22v-.59a.2.2,0,0,1,.22-.22h8.45A.19.19,0,0,1,332.67,62.11Z"
        />
        <path
          d="M351.77,62.92H345c-.05,0-.08,0-.08.09v6c0,.06,0,.09.08.09h4.62a.19.19,0,0,1,.22.22v.6c0,.14-.07.21-.22.21H345c-.05,0-.08,0-.08.09v6c0,.06,0,.08.08.08h6.76a.2.2,0,0,1,.22.22v.6a.19.19,0,0,1-.22.22H344a.19.19,0,0,1-.22-.22v-15a.19.19,0,0,1,.22-.22h7.79a.19.19,0,0,1,.22.22v.59A.19.19,0,0,1,351.77,62.92Z"
        />
        <path
          d="M66,1.25.85,66.42A.5.5,0,0,1,0,66.07V49a2,2,0,0,1,.59-1.41L47.19,1A2,2,0,0,1,48.6.39H65.67A.51.51,0,0,1,66,1.25Z"
        />
        <path
          d="M13.51,1.39V14.72a2,2,0,0,1-.59,1.41L.85,28.2A.5.5,0,0,1,0,27.85V1.39a1,1,0,0,1,1-1H12.51A1,1,0,0,1,13.51,1.39Z"
        />
        <path
          d="M65.68,76.83H48.61a2,2,0,0,1-1.41-.59L29.37,58.43a1,1,0,0,1,0-1.42l8.14-8.14a1,1,0,0,1,1.41,0L66,76A.51.51,0,0,1,65.68,76.83Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<style scoped>
path {
  fill: var(--col-black);
}
</style>
