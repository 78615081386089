export default {
  name: {
    required: {
      message: {
        de: 'Bitte geben Sie einen Namen an.',
        en: 'Please enter a name.',
      },
    },
    min: {
      characters: 2,
      message: {
        de: 'Der Name muss mindestens zwei Zeichen lang sein.',
        en: 'The name must be a minimum of 2 characters.',
      },
    },
    max: {
      characters: 64,
      message: {
        de: 'Der Name darf nicht mehr als 64 Zeichen lang sein.',
        en: 'The name must be a maximum of 64 characters.',
      },
    },
  },
  email: {
    required: {
      message: {
        de: 'Bitte geben Sie eine E-Mail-Adresse an.',
        en: 'Please enter an email address.',
      },
    },
    email: {
      message: {
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
        en: 'Please enter a valid email address.',
      },
    },
  },
  telefon: {
    min: {
      characters: 6,
      message: {
        de: 'Bitte geben Sie eine gültige Telefonnummer an.',
        en: 'Please enter a valid telephone number.',
      },
    },
    max: {
      characters: 20,
      message: {
        de: 'Bitte geben Sie eine gültige Telefonnummer an.',
        en: 'Please enter a valid telephone number',
      },
    },
  },
  betreff: {
    min: {
      characters: 2,
      message: {
        de: 'Der Betreff muss mindestens zwei Zeichen lang sein.',
        en: 'The subject must be a minimum of 2 characters.',
      },
    },
    max: {
      characters: 128,
      message: {
        de: 'Der Betreff darf nicht mehr als 128 Zeichen lang sein.',
        en: 'The subject must be a maximum of 128 characters.',
      },
    },
  },
  anfrage: {
    required: {
      message: {
        de: 'Bitte verfassen Sie einen Anfragetext.',
        en: 'Please enter a message.',
      },
    },
    min: {
      characters: 16,
      message: {
        de: 'Bitte verfassen Sie einen ausführlicheren Anfragetext.',
        en: 'Please write a longer message.',
      },
    },
    max: {
      characters: 20000,
      message: {
        de: 'Der Anfragetext darf nicht mehr als 20.000 Zeichen lang sein.',
        en: 'The message must be a maximum of 20,000 characters.',
      },
    },
  },
  datenschutz_akzeptiert: {
    accepted: {
      message: {
        de: 'Wir benötigen Ihre Zustimmung, um Ihre Daten verarbeiten zu dürfen.',
        en: 'We need your consent before we can process your data.',
      },
    },
  },
}
