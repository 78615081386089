<template>
  <footer>
    <div class="footer-inner-wrap">
      <div class="info">
        <address class="info-section anschrift">
          <h2>KRIST RECHTSANWÄLTE</h2>
          <div class="anwaelte">
            <p>Dr. Josef Krist</p>
            <p>Dr. Andreas Krist LL.M.</p>
          </div>
          <div class="strasse">
            <p>Liebiggasse 4</p>
            <p>1010 Wien</p>
          </div>
        </address>

        <div class="info-section kontakt">
          <h2>{{ lang == 'de' ? 'KONTAKT' : 'CONTACT' }}</h2>
          <div class="telefon">
            <p class="flex">
              <span>Tel</span>
              <span>
                +43 (1) 40 80 625
                <br />
                +43 (1) 40 80 626
              </span>
            </p>
          </div>
          <div class="fax">
            <p class="flex">
              <span>Fax</span>
              <span>+43 (1) 40 80 625 20</span>
            </p>
          </div>
          <div class="email-und-web">
            <p>email: office@ra-krist.at</p>
            <p>www.ra-krist.at</p>
          </div>
        </div>

        <div class="info-section buerozeiten">
          <h2>{{ lang == 'de' ? 'BÜROZEITEN' : 'OFFICE HOURS' }}</h2>
          <div class="zeiten">
            <p class="flex" v-for="(buerozeit, i) in buerozeiten" :key="i">
              <span>{{ buerozeit[lang].tage }}</span>
              <span>{{ buerozeit[lang].zeiten }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="links">
        <div class="routes">
          <router-link :to="{ name: 'Datenschutz' }">
            {{ lang == 'de' ? 'DATENSCHUTZ' : 'PRIVACY POLICY' }}
          </router-link>
          <span class="pipe"></span>
          <router-link :to="{ name: 'Impressum' }">
            {{ lang == 'de' ? 'IMPRESSUM' : 'IMPRINT' }}
          </router-link>
        </div>

        <div class="su">
          Website:
          <a href="//seitenumbruch.at" target="_blank">Seitenumbruch</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import buerozeiten from '@/assets/data/buerozeiten'

export default {
  name: 'Footer',
  data() {
    return {
      buerozeiten,
    }
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
}
</script>

<style scoped>
footer {
  padding: 56px 0;
  background-color: var(--col-footer);
}

.footer-inner-wrap {
  width: var(--content-width);
  margin: 0 auto;
  font-family: var(--font-condensed);
  font-size: 14px;
  letter-spacing: 0.07em;
  white-space: nowrap;
}

@media all and (min-width: 400px) {
  .footer-inner-wrap {
    font-size: 16px;
  }
}

@media all and (min-width: 1280px) {
  .footer-inner-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
  }
}

.info,
.links {
  margin: 0 auto;
}

@media all and (min-width: 1280px) {
  .info,
  .links {
    margin: 0;
  }
}

.info {
  line-height: 1.6em;
  max-width: 320px;
}

@media all and (min-width: 640px) {
  .info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 600px;
    row-gap: 64px;
  }
}

@media all and (min-width: 960px) {
  .info {
    max-width: 960px;
  }
}

@media all and (min-width: 1280px) {
  .info {
    max-width: none;
  }
}

.info-section {
  width: 224px;
  flex-shrink: 0;
}

@media all and (min-width: 1280px) {
  .info-section {
    width: calc((var(--content-width) - 176px) / 3);
  }
}

.info-section:not(:first-child) {
  margin-top: calc(1em * 1.6 + 1.5em);
}

@media all and (min-width: 640px) {
  .info-section:not(:first-child) {
    margin-top: initial;
  }
}

@media all and (min-width: 960px) {
  .kontakt {
    order: 3;
  }
  .buerozeiten {
    order: 2;
  }
}

@media all and (min-width: 1280px) {
  .kontakt {
    order: 2;
  }
  .buerozeiten {
    order: 3;
  }
}

.info-section h2 {
  font-weight: var(--weight-semibold);
}

.info-section > div {
  margin-top: 1em;
}

.info-section .flex {
  display: flex;
}

.buerozeiten .flex span:first-child {
  width: 5.5em;
}

.kontakt .flex span:first-child {
  width: 3.5em;
}

.links {
  margin-top: 56px;
  text-align: center;
}

@media all and (min-width: 1280px) {
  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: calc(1.6em + 1em);
  }
}

.routes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (min-width: 320px) {
  .routes {
    display: initial;
  }
}

@media all and (min-width: 1280px) {
  .routes {
    display: flex;
    align-items: flex-end;
  }
}

.routes a {
  display: inline-block;
  position: relative;
  letter-spacing: 0.3em;
  margin-right: -0.3em;
}

.routes a:nth-of-type(2) {
  margin-top: 0.75em;
}

@media all and (min-width: 320px) {
  .routes a:nth-of-type(2) {
    margin-top: initial;
  }
}

@media all and (min-width: 1280px) {
  .routes a:nth-of-type(2) {
    margin-top: 0.75em;
  }
}

.routes a::after {
  content: '';
  position: absolute;
  width: calc(100% - 0.3em);
  bottom: -0.5em;
  left: 0;
  border-bottom: 1px solid var(--col-black);
  opacity: 0;
  transform: translate3d(0, -3px, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1), opacity 250ms linear;
}

.routes a:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.routes .pipe {
  display: none;
  height: 1.25em;
  margin: 0 1.25em;
  border-right: 1px solid var(--col-black);
  transform: translateY(0.25em);
}

@media all and (min-width: 320px) {
  .routes .pipe {
    display: inline-block;
  }
}

@media all and (min-width: 1280px) {
  .routes .pipe {
    display: none;
  }
}

.su {
  margin-top: 32px;
  margin-top: 24px;
  font-size: 14px;
}

@media all and (min-width: 400px) {
  .su {
    font-size: 16px;
  }
}

.su a {
  position: relative;
}

.su a::after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -0.2em;
  left: 0;
  border-bottom: 1px solid var(--col-black);
  opacity: 0;
  transform: translate3d(0, -3px, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1), opacity 250ms linear;
}

.su a:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
