<template>
  <div class="menu">
    <transition name="menu" appear>
      <nav v-if="menuOpen">
        <ul>
          <li>
            <router-link class="route-link" :to="{ name: 'Home' }">
              HOME
            </router-link>
          </li>
          <li>
            <router-link class="route-link" :to="{ name: 'Kanzlei' }">
              {{ lang == 'de' ? 'KANZLEI' : 'ABOUT' }}
            </router-link>
          </li>
          <li>
            <router-link class="route-link" :to="{ name: 'Fachgebiete' }">
              {{ lang == 'de' ? 'FACHGEBIETE' : 'EXPERTISE' }}
            </router-link>
          </li>
          <li>
            <router-link class="route-link" :to="{ name: 'Team' }">
              TEAM
            </router-link>
          </li>
          <li v-if="honorar.published">
            <router-link class="route-link" :to="{ name: 'Honorar' }">
              {{ lang == 'de' ? 'HONORAR' : 'MANDATE & FEE' }}
            </router-link>
          </li>
          <li>
            <router-link class="route-link" :to="{ name: 'Kontakt' }">
              {{ lang == 'de' ? 'KONTAKT' : 'CONTACT' }}
            </router-link>
          </li>
        </ul>
        <language-toggle class="language-toggle"></language-toggle>
      </nav>
    </transition>

    <button class="menu-button" :class="{ active: menuOpen }" @click="toggleMenu">
      <div class="line menu-top"></div>
      <div class="line menu-middle"></div>
      <div class="line menu-bottom"></div>
      <div class="line x-down"></div>
      <div class="line x-up"></div>
    </button>
  </div>
</template>

<script>
import axios from 'axios'

import LanguageToggle from '@/components/LanguageToggle'

export default {
  name: 'MenuMobile',
  components: {
    LanguageToggle,
  },
  data() {
    return {
      honorar: {},
      menuOpen: false,
    }
  },
  created() {
    axios.get(`${this.lang}/honorar`).then(response => (this.honorar = response.data.data))
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
  watch: {
    $route() {
      this.menuOpen = false
      this.allowScroll()
      axios.get(`${this.lang}/honorar`).then(response => (this.honorar = response.data.data))
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
      if (this.menuOpen) this.preventScroll()
      else this.allowScroll()
    },
    preventScroll() {
      document.documentElement.style.overflowY = 'hidden'
      document.body.style.overflowY = 'hidden'
    },
    allowScroll() {
      document.documentElement.style.overflowY = 'initial'
      document.body.style.overflowY = 'initial'
    },
  },
}
</script>

<style scoped>
.menu {
  position: relative;
}

.menu-button {
  --button-size: 48px;
  display: grid;
  position: relative;
  place-items: center;
  width: var(--button-size);
  height: var(--button-size);
}

.menu-button .line {
  --line-height: 2px;
  --line-offset: 8px;
  width: 32px;
  height: var(--line-height);
  background-color: var(--col-black);
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1),
    opacity 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

@media all and (min-width: 480px) {
  .menu-button .line {
    --line-offset: 10px;
    width: 40px;
  }
}

.menu-button .line {
  position: absolute;
  top: calc(50% - var(--line-height) / 2);
}

.menu-button .line.menu-top {
  transform: translate3d(0, calc(var(--line-offset) * -1), 0);
}

.menu-button .line.menu-bottom {
  transform: translate3d(0, var(--line-offset), 0);
}

.menu-button.active .line.menu-top {
  opacity: 0;
  transform: translate3d(15px, calc(var(--line-offset) * -1), 0);
}

.menu-button.active .line.menu-middle {
  opacity: 0;
  transform: translate3d(-30px, 0, 0);
}

.menu-button.active .line.menu-bottom {
  opacity: 0;
  transform: translate3d(45px, var(--line-offset), 0);
}

.menu-button .line.x-up {
  opacity: 0;
  transform: rotate(-45deg) scale3d(0, 1, 1);
}

.menu-button .line.x-down {
  opacity: 0;
  transform: rotate(45deg) scale3d(0, 1, 1);
}

.menu-button.active .line.x-up {
  opacity: 1;
  transform: rotate(-45deg) scale3d(1, 1, 1);
}

.menu-button.active .line.x-down {
  opacity: 1;
  transform: rotate(45deg) scale3d(1, 1, 1);
}

nav {
  display: grid;
  place-items: center;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--col-white);
}

.route-link {
  display: inline-block;
  position: relative;
  padding: 1em 1.2em 1em 1.5em;
  font-family: var(--font-condensed);
  font-size: min(4vh, 7vw);
  letter-spacing: 0.3em;
}

.route-link::after {
  content: '';
  position: absolute;
  width: calc(100% - 1.5em - 1.5em);
  left: 1.5em;
  bottom: 0.5em;
  border-bottom: 2px solid var(--col-primary);
  opacity: 0;
  transform: translate3d(0, -4px, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1), opacity 250ms linear;
}

.route-link.active::after,
.route-link:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.menu-enter,
.menu-leave-to {
  opacity: 0;
}

.menu-enter li {
  opacity: 0;
  transform: translate3d(4em, 0, 0);
}

.menu-enter-active {
  transition: opacity 400ms cubic-bezier(0.5, 0, 0.5, 1);
}

.menu-enter-active li {
  transition: transform 750ms cubic-bezier(0, 0.5, 0.5, 1),
    opacity 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.menu-enter-active li:nth-child(1) {
  transition-delay: 250ms;
}

.menu-enter-active li:nth-child(2) {
  transition-delay: 375ms;
}

.menu-enter-active li:nth-child(3) {
  transition-delay: 500ms;
}

.menu-enter-active li:nth-child(4) {
  transition-delay: 625ms;
}

.menu-enter-active li:nth-child(5) {
  transition-delay: 750ms;
}

.menu-enter-active li:nth-child(6) {
  transition-delay: 875ms;
}

.menu-leave-active {
  transition: opacity 400ms cubic-bezier(0.5, 0, 0.5, 1) 625ms;
}

.menu-leave-to li {
  opacity: 0;
  transform: translate3d(-3em, 0, 0);
}

.menu-leave-active li {
  transition: transform 500ms cubic-bezier(0.25, 0, 1, 0.5),
    opacity 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.menu-leave-active li:nth-child(1) {
  transition-delay: 0ms;
}

.menu-leave-active li:nth-child(2) {
  transition-delay: 62.5ms;
}

.menu-leave-active li:nth-child(3) {
  transition-delay: 125ms;
}

.menu-leave-active li:nth-child(4) {
  transition-delay: 187.5ms;
}

.menu-leave-active li:nth-child(5) {
  transition-delay: 250ms;
}

.menu-leave-active li:nth-child(6) {
  transition-delay: 312.5ms;
}

.language-toggle {
  position: absolute;
  right: 48px;
  bottom: 24px;
}
</style>
