<template>
  <v-page>
    <main class="kontakt">
      <h1 class="page-title">{{ lang == 'de' ? 'Kontakt' : 'Contact' }}</h1>

      <div class="info text">
        <div class="box">
          <div class="text-box">
            <address class="info-section">
              <h3>KRIST RECHTSANWÄLTE</h3>
              <div class="strasse">
                <p>Liebiggasse 4</p>
                <p>1010 Wien</p>
              </div>
              <div class="telefon">
                <p class="flex">
                  <span>Tel</span>
                  <span>
                    +43 (1) 40 80 625
                    <br />
                    +43 (1) 40 80 626
                  </span>
                </p>
              </div>
              <div class="fax">
                <p class="flex">
                  <span>Fax</span>
                  <span>+43 (1) 40 80 625 20</span>
                </p>
              </div>
              <div class="email-und-web">
                <p>email: office@ra-krist.at</p>
                <p>www.ra-krist.at</p>
              </div>
            </address>

            <div class="info-section buerozeiten">
              <h3>{{ lang == 'de' ? 'BÜROZEITEN' : 'OFFICE HOURS' }}</h3>
              <div class="zeiten">
                <p class="flex" v-for="(buerozeit, i) in buerozeiten" :key="i">
                  <span>{{ buerozeit[lang].tage }}</span>
                  <span>{{ buerozeit[lang].zeiten }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="lageplan-wrap"><v-lageplan class="lageplan"></v-lageplan></div>
        </div>
      </div>

      <div ref="formSection" class="termin-formular" v-if="formStatus === 'initial'">
        <h2 class="section-title">
          {{ lang == 'de' ? 'VEREINBAREN SIE EINEN TERMIN' : 'ARRANGE AN APPOINTMENT' }}
        </h2>

        <p class="kontakt-aufforderung text">
          {{
            lang == 'de'
              ? 'Für eine Terminvereinbarung ersuchen wir Sie um Ihren Anruf oder Ihre Nachricht.'
              : 'To arrange an appointment please give us a call or submit a message below.'
          }}
        </p>

        <p class="errors text" v-if="errors.length">
          {{
            lang == 'de'
              ? 'Bitte korrigieren Sie die markierten Felder.'
              : 'Please correct the highlighted errors below.'
          }}
        </p>

        <form @submit.prevent>
          <div class="row small-fields">
            <div class="feld">
              <label for="name">NAME*</label>
              <input
                type="text"
                name="name"
                v-model="form.name"
                :class="{ warn: hasError('name', errors) }"
                @keyup="
                  hasError('name', errors) &&
                    validateField('name', $event.target.value, errors, validationRules['name'])
                "
              />
              <div class="error" v-if="hasError('name', errors)">
                {{ getError('name', errors).message[lang] }}
              </div>
            </div>
          </div>

          <div class="row small-fields">
            <div class="feld">
              <label for="email">{{ lang == 'de' ? 'E-MAIL-ADRESSE*' : 'EMAIL ADDRESS' }}</label>
              <input
                type="text"
                name="email"
                v-model="form.email"
                :class="{ warn: hasError('email', errors) }"
                @keyup="
                  hasError('email', errors) &&
                    validateField('email', $event.target.value, errors, validationRules['email'])
                "
              />
              <div class="error" v-if="hasError('email', errors)">
                {{ getError('email', errors).message[lang] }}
              </div>
            </div>
            <div class="feld">
              <label for="telefon">{{ lang == 'de' ? 'TELEFONNUMMER' : 'TELEPHONE NUMBER' }}</label>
              <input
                type="text"
                name="telefon"
                v-model="form.telefon"
                :class="{ warn: hasError('telefon', errors) }"
                @keyup="
                  hasError('telefon', errors) &&
                    validateField(
                      'telefon',
                      $event.target.value,
                      errors,
                      validationRules['telefon']
                    )
                "
              />
              <div class="error" v-if="hasError('telefon', errors)">
                {{ getError('telefon', errors).message[lang] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="feld">
              <label for="betreff">{{ lang == 'de' ? 'BETREFF' : 'SUBJECT' }}</label>
              <input
                type="text"
                name="betreff"
                v-model="form.betreff"
                :class="{ warn: hasError('betreff', errors) }"
                @keyup="
                  hasError('betreff', errors) &&
                    validateField(
                      'betreff',
                      $event.target.value,
                      errors,
                      validationRules['betreff']
                    )
                "
              />
              <div class="error" v-if="hasError('betreff', errors)">
                {{ getError('betreff', errors).message[lang] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="feld">
              <label for="anfrage">{{ lang == 'de' ? 'IHRE ANFRAGE*' : 'YOUR INQUIRY*' }}</label>
              <textarea
                name="anfrage"
                v-model="form.anfrage"
                rows="20"
                :class="{ warn: hasError('anfrage', errors) }"
                @keyup="
                  hasError('anfrage', errors) &&
                    validateField(
                      'anfrage',
                      $event.target.value,
                      errors,
                      validationRules['anfrage']
                    )
                "
              />
              <div class="error" v-if="hasError('anfrage', errors)">
                {{ getError('anfrage', errors).message[lang] }}
              </div>
            </div>
          </div>

          <div
            class="row datenschutz"
            :class="{ warn: hasError('datenschutz_akzeptiert', errors) }"
          >
            <label for="datenschutz_akzeptiert">
              <input
                type="checkbox"
                name="datenschutz_akzeptiert"
                id="datenschutz_akzeptiert"
                v-model="form.datenschutz_akzeptiert"
              />
              <span class="text">
                {{
                  lang == 'de'
                    ? 'Ich bin damit einverstanden, dass meine Daten zum Zwecke der Bearbeitung der Anfrage gespeichert und weiterverarbeitet werden.'
                    : 'I consent to my data being recorded, processed and used for the purposes of processing my inquiry.'
                }}
              </span>
            </label>
            <div class="error" v-if="hasError('datenschutz_akzeptiert', errors)">
              {{ getError('datenschutz_akzeptiert', errors).message[lang] }}
            </div>
          </div>

          <button class="button" @click="submit">
            {{ lang == 'de' ? 'ABSCHICKEN' : 'SUBMIT' }}
          </button>
        </form>
      </div>

      <div ref="thankYou" class="thank-you" v-if="formStatus === 'success'">
        <h2 class="section-title">
          {{ lang == 'de' ? 'VIELEN DANK FÜR IHRE ANFRAGE!' : 'THANK YOU FOR YOUR INQUIRY!' }}
        </h2>

        <p class="thank-you-text text">
          {{
            lang == 'de'
              ? 'Wir werden uns so bald wie möglich bei Ihnen melden.'
              : 'We will get back to you as soon as possible.'
          }}
        </p>
      </div>

      <div ref="somethingWentWrong" class="something-went-wrong" v-if="formStatus === 'error'">
        <h2 class="section-title">
          {{
            lang == 'de'
              ? 'LEIDER IST EIN UNERWARTETER FEHLER AUFGETRETEN!'
              : 'AN UNEXPECTED ERROR OCCURRED!'
          }}
        </h2>

        <p class="text">
          {{
            lang == 'de'
              ? 'Wir bitten vielmals um Entschuldigung. Bitte nehmen Sie auf einem anderen Weg Kontakt zu uns auf.'
              : 'We deeply apologize for the inconvenience. Please contact us by telephone or e-mail.'
          }}
        </p>
      </div>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import buerozeiten from '@/assets/data/buerozeiten'

import formValidation from '@/assets/js/formValidation'
import validationRules from '@/assets/js/validationRules/kontaktformular'

import VPage from '@/components/Page'
import VLageplan from '@/components/Lageplan'

export default {
  name: 'Kontakt',
  components: {
    VPage,
    VLageplan,
  },
  data() {
    return {
      buerozeiten,
      form: {
        name: '',
        email: '',
        telefon: '',
        betreff: '',
        anfrage: '',
        datenschutz_akzeptiert: false,
      },
      errors: [],
      validationRules,
      formStatus: 'initial',
    }
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
    submitActive() {
      const allRequiredPresent = Object.entries(this.validationRules).reduce(
        (allPresent, [field, rules]) => {
          if ((rules.if ? rules.if() : true) && rules.required) {
            return allPresent && this.form[field].length != 0
          } else {
            return allPresent
          }
        },
        true
      )
      return allRequiredPresent && this.errors.length == 0 && this.form.datenschutz_akzeptiert
    },
  },
  methods: {
    ...formValidation,
    toggleDatenschutz(newValue) {
      this.form.datenschutz_akzeptiert = newValue
    },
    async submit() {
      this.validateAll(this.form, this.errors, this.validationRules)
      if (this.errors.length > 0) {
        this.$refs.formSection.scrollIntoView()
        return
      }

      try {
        this.form.lang = this.lang
        await axios.post('/kontaktanfrage', this.form)
        this.formStatus = 'success'
        this.$nextTick(() => {
          this.$refs.thankYou.scrollIntoView()
        })
      } catch (err) {
        this.formStatus = 'error'
      }
    },
  },
}
</script>

<style scoped>
.info {
  max-width: 600px;
  margin: 0 auto;
  font-size: var(--font-size-regular);
  font-family: var(--font-condensed);
  line-height: 1.6em;
  letter-spacing: 0.07em;
}

@media all and (min-width: 960px) {
  .info {
    max-width: 800px;
  }
}

.text-box {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 52px;
}

@media all and (min-width: 720px) {
  .text-box {
    grid-template-columns: 1fr 1fr;
  }
}

.info h3 {
  margin: 0;
}

.info-section > div {
  margin-top: 0.8em;
}

.info-section .flex {
  display: flex;
}

.telefon .flex span:first-child,
.fax .flex span:first-child {
  width: 3em;
}

.buerozeiten .flex span:first-child {
  width: 5.5em;
}

.lageplan-wrap {
  position: relative;
  width: 100vw;
  left: 50%;
  margin-top: 56px;
  transform: translateX(-50%);
}

@media all and (min-width: 560px) {
  .lageplan-wrap {
    width: 100%;
  }
}

@media all and (min-width: 960px) {
  .lageplan-wrap {
    grid-area: 1 / 1;
  }
}

/* ----------------------------- */

.termin-formular,
.thank-you,
.something-went-wrong {
  --row-gap: 32px;
  max-width: 800px;
  margin-top: 96px;
  margin-left: auto;
  margin-right: auto;
}

@media all and (min-width: 800px) {
  .termin-formular,
  .thank-you,
  .something-went-wrong {
    --row-gap: 40px;
    margin-top: 128px;
  }
}

.something-went-wrong .section-title {
  color: var(--col-warn);
}

.errors {
  margin-top: 1em;
  color: var(--col-warn);
  font-weight: var(--weight-semibold);
}

@media all and (min-width: 800px) {
  .termin-formular .row.small-fields {
    display: grid;
    column-gap: 4em;
    grid-template-columns: 1fr 1fr;
  }
}

.termin-formular form {
  margin-top: 16px;
}

.termin-formular .feld {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: var(--row-gap);
}

.termin-formular .feld label {
  display: block;
  font-family: var(--font-condensed);
  font-size: 16px;
  letter-spacing: 0.3em;
}

.termin-formular .feld input,
.termin-formular .feld textarea {
  width: 100%;
  margin-top: 0.5em;
  padding: 0.75em 0.5em;
  border: 1px solid var(--col-primary);
}

.termin-formular .feld input.warn,
.termin-formular .feld textarea.warn {
  border: 1px solid var(--col-warn);
}

.termin-formular .feld textarea {
  line-height: 1.2;
}

.termin-formular .error {
  margin: 0.25rem 0.5rem -1.125rem;
  color: var(--col-warn);
  font-size: calc(var(--font-size-regular) - 2px);
}

.termin-formular .datenschutz {
  margin-top: calc(var(--row-gap) * 1.5);
}

.termin-formular .datenschutz label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.termin-formular input[type='checkbox'] {
  flex-shrink: 0;
  position: relative;
  height: 1.75em;
  width: 1.75em;
  margin-top: 0.375em;
  border: 1px solid var(--col-primary);
  cursor: pointer;
}

.termin-formular input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  background-color: var(--col-primary);
}

.termin-formular .datenschutz {
  position: relative;
}

.termin-formular .datenschutz.warn::before {
  content: '';
  display: block;
  position: absolute;
  top: -1em;
  bottom: -1em;
  left: -1em;
  right: -1em;
  border: 1px solid var(--col-warn);
  z-index: -1;
}

.termin-formular .datenschutz span {
  margin-left: 16px;
  line-height: 1.3em;
}

.termin-formular .button {
  margin-top: calc(var(--row-gap) * 1.5);
  padding: 0.75em 2em;
  font-family: var(--font-condensed);
  font-size: 16px;
  letter-spacing: 0.2em;
  border: 1px solid var(--col-primary);
  transition: background-color 125ms linear, border-color 125ms linear, color 125ms linear;
}

.termin-formular .button:hover {
  background-color: var(--col-primary);
}
</style>
