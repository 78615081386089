<template>
  <button class="scroll-indicator" @click="scrollDown">
    <svg viewBox="0 0 44 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43 1L22 12L1 1" stroke-width="2" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ScrollIndicator',
  methods: {
    scrollDown() {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
.scroll-indicator {
  display: grid;
  place-items: center;
  height: 32px;
  width: 64px;
  cursor: pointer;
}

svg {
  height: 14px;
}

path {
  stroke: var(--col-primary);
}
</style>
