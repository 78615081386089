<template>
  <v-page>
    <main class="home">
      <section class="hero">
        <div class="img-wrap parallax-container">
          <responsive-image
            class="parallax-item"
            :urls="{
              webp: [
                { size: 640, url: require('@/assets/img/haus/haus-640.webp') },
                { size: 960, url: require('@/assets/img/haus/haus-960.webp') },
                { size: 1280, url: require('@/assets/img/haus/haus-1280.webp') },
                { size: 1920, url: require('@/assets/img/haus/haus-1920.webp') },
                { size: 2880, url: require('@/assets/img/haus/haus-2880.webp') },
                { size: 3840, url: require('@/assets/img/haus/haus-3840.webp') },
              ],
              jpg: [
                { size: 640, url: require('@/assets/img/haus/haus-640.jpg') },
                { size: 960, url: require('@/assets/img/haus/haus-960.jpg') },
                { size: 1280, url: require('@/assets/img/haus/haus-1280.jpg') },
                { size: 1920, url: require('@/assets/img/haus/haus-1920.jpg') },
                { size: 2880, url: require('@/assets/img/haus/haus-2880.jpg') },
                { size: 3840, url: require('@/assets/img/haus/haus-3840.jpg') },
              ],
            }"
            sizes="(max-aspect-ratio: 1) 100vh, 100vw"
            :alt="lang == 'de' ? 'Außenansicht' : 'Exterior'"
          ></responsive-image>
        </div>
        <scroll-indicator class="scroll-indicator"></scroll-indicator>
      </section>

      <section class="kanzlei">
        <h1 class="section-title center">{{ lang == 'de' ? 'DIE KANZLEI' : 'ABOUT' }}</h1>
        <article class="text hyphenate show-line-breaks" v-html="home.kanzlei"></article>
      </section>

      <section class="fachgebiete">
        <h1 class="section-title center">{{ lang == 'de' ? 'FACHGEBIETE' : 'EXPERTISE' }}</h1>
        <fachgebiete-uebersicht-liste :fachgebiete="fachgebiete"></fachgebiete-uebersicht-liste>
      </section>

      <div
        v-if="home.team || home.honorar"
        class="team-und-honorar"
        :class="{ 'has-both': home.team && home.honorar }"
      >
        <section v-if="home.team" class="team">
          <h1 class="section-title" :class="{ center: !home.honorar }">
            {{ lang == 'de' ? 'DAS TEAM' : 'TEAM' }}
          </h1>
          <article :class="{ center: !home.honorar }">
            <div class="text hyphenate" v-html="home.team"></div>
            <mehr-erfahren-link :to="{ name: 'Team' }">
              {{ lang == 'de' ? 'ERFAHREN SIE MEHR' : 'LEARN MORE' }}
            </mehr-erfahren-link>
          </article>
        </section>

        <section class="honorar" v-if="home.honorar">
          <h1 class="section-title" :class="{ center: !home.team }">
            {{ lang == 'de' ? 'HONORAR' : 'MANDATE & FEE' }}
          </h1>
          <article :class="{ center: !home.honorar }">
            <div class="text hyphenate" v-html="home.honorar"></div>
            <mehr-erfahren-link :to="{ name: 'Honorar' }">
              {{ lang == 'de' ? 'ERFAHREN SIE MEHR' : 'LEARN MORE' }}
            </mehr-erfahren-link>
          </article>
        </section>
      </div>

      <section class="kontakt">
        <h1 class="section-title center">{{ lang == 'de' ? 'KONTAKT' : 'CONTACT' }}</h1>
        <div class="box">
          <div class="info">
            <address>
              <h2>KRIST RECHTSANWÄLTE</h2>
              <p class="anschrift">
                Liebiggasse 4
                <br />
                1010 Wien
              </p>
              <div class="nummern">
                <p>
                  <span>Tel</span>
                  <span>
                    +43 (1) 40 80 625
                    <br />
                    +43 (1) 40 80 626
                  </span>
                </p>
                <p>
                  <span>Fax</span>
                  <span>+43 (1) 40 80 625 20</span>
                </p>
              </div>
              <p class="web">
                email: office@ra-krist.at
                <br />
                www.ra-krist.at
              </p>
            </address>
            <div class="buerozeiten">
              <h2>{{ lang == 'de' ? 'BÜROZEITEN' : 'OFFICE HOURS' }}</h2>
              <div class="zeiten">
                <p v-for="(buerozeit, i) in buerozeiten" :key="i">
                  <span>{{ buerozeit[lang].tage }}</span>
                  <span>{{ buerozeit[lang].zeiten }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="lageplan-wrap"><v-lageplan class="lageplan"></v-lageplan></div>
        </div>
      </section>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import { startParallax, endParallax } from '@/assets/js/parallax'

import buerozeiten from '@/assets/data/buerozeiten'

import VPage from '@/components/Page'
import ResponsiveImage from '@/components/ResponsiveImage'
import ScrollIndicator from '@/components/ScrollIndicator'
import FachgebieteUebersichtListe from '@/components/FachgebieteUebersichtListe'
import MehrErfahrenLink from '@/components/MehrErfahrenLink'
import VLageplan from '@/components/Lageplan'

export default {
  name: 'Home',
  components: {
    VPage,
    ResponsiveImage,
    ScrollIndicator,
    FachgebieteUebersichtListe,
    MehrErfahrenLink,
    VLageplan,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      home: {},
      fachgebiete: [],
      buerozeiten,
    }
  },
  async beforeRouteEnter(to, from, next) {
    const [homeRes, fachgebieteRes] = await Promise.all([
      axios.get(`${to.params.lang}/home`),
      axios.get(`${to.params.lang}/fachgebiete`),
    ])
    next(vm => {
      vm.home = homeRes.data.data
      vm.fachgebiete = fachgebieteRes.data.data
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const [homeRes, fachgebieteRes] = await Promise.all([
      axios.get(`${to.params.lang}/home`),
      axios.get(`${to.params.lang}/fachgebiete`),
    ])
    this.home = homeRes.data.data
    this.fachgebiete = fachgebieteRes.data.data
    next()
  },
  mounted() {
    startParallax({ fps: 60 })
  },
  beforeDestroy() {
    endParallax()
  },
}
</script>

<style scoped>
.home {
  --section-top: min(128px, 14vh);
  --section-bottom: min(112px, 12vh);
  width: 100%;
}

@media not all and (min-width: 960px) {
  .home {
    padding-bottom: 128px;
  }
}

.hero {
  position: relative;
  margin-top: var(--header-height);
  height: calc(100vh - var(--header-height));
}

.hero .img-wrap {
  height: calc(100% - 64px);
  overflow: hidden;
}

.hero >>> img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.scroll-indicator {
  margin: 16px auto 0;
}

.kanzlei,
.fachgebiete,
.team-und-honorar,
.kontakt {
  width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}

.kanzlei,
.team,
.honorar,
.kontakt {
  margin-top: var(--section-top);
  margin-bottom: var(--section-bottom);
}

.section-title {
  margin-bottom: min(calc((1em / 26 * 160) / 2 + 4vw), 5em);
  font-size: min(32px, calc(16px + 3vw));
}

.section-title::after {
  width: calc(1em / 32 * 96);
  bottom: calc(-1em / 32 * 56);
}

.kanzlei,
.team,
.honorar {
  text-align: justify;
}

.kanzlei article {
  max-width: 800px;
  margin: 0 auto;
}

.fachgebiete {
  position: relative;
  padding: var(--section-top) 0 var(--section-bottom);
}

.fachgebiete::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 50%;
  background-color: var(--col-gray-light);
  transform: translateX(-50%);
  z-index: -1;
}

@media not all and (min-width: 960px) {
  .team-und-honorar {
    max-width: 600px;
  }

  .team-und-honorar .section-title {
    text-align: center;
  }

  .team-und-honorar .section-title::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media all and (min-width: 960px) {
  .team-und-honorar.has-both {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: min(10vw, 176px);
  }

  .team,
  .honorar {
    margin-bottom: 0;
  }

  .team-und-honorar.has-both .section-title {
    margin-bottom: calc(1em / 26 * 96);
  }
}

.team article.center {
  max-width: 480px;
  margin: 0 auto;
}

.kontakt {
  margin-bottom: 0;
}

@media all and (min-width: 960px) {
  .kontakt .box {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-content: initial;
    column-gap: min(96px, 7vw);
  }
}

.kontakt .info {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10vw;
  row-gap: 52px;
  font-size: var(--font-size-regular);
  font-family: var(--font-condensed);
  line-height: 1.6em;
  letter-spacing: 0.07em;
}

@media not all and (min-width: 960px) {
  .kontakt .info {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media all and (min-width: 720px) {
  .kontakt .info {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (min-width: 960px) {
  .kontakt .info {
    grid-template-columns: 1fr;
  }
}

.kontakt h2 {
  font-weight: var(--weight-semibold);
  line-height: 1em;
}

.kontakt .anschrift,
.kontakt .nummern,
.kontakt .web {
  margin-top: 1.25em;
}

.kontakt .nummern p {
  display: flex;
}

.kontakt .nummern p span:first-child {
  width: 3.5em;
}

.kontakt .buerozeiten .zeiten {
  margin-top: 1.25em;
}

.kontakt .buerozeiten p {
  display: flex;
}

.kontakt .buerozeiten p span:first-child {
  width: 5.5em;
}

.lageplan-wrap {
  position: relative;
  width: 100vw;
  left: 50%;
  margin-top: 56px;
  transform: translateX(-50%);
}

@media all and (min-width: 560px) {
  .lageplan-wrap {
    width: min(100%, 600px);
  }
}

@media all and (min-width: 960px) {
  .lageplan-wrap {
    grid-area: 1 / 1;
    width: auto;
    margin-top: 0;
  }
}
</style>
