<template>
  <v-page>
    <main class="publikationen">
      <h1 class="page-title">{{ lang == 'de' ? 'Publikationen' : 'Publications' }}</h1>

      <section v-for="{ id, name, publikationen } in rechtsanwaelteWithPublikationen" :key="id">
        <span class="jump-target" :id="id"></span>
        <h2 class="section-title">{{ name }}</h2>
        <ul class="text">
          <li v-for="({ publikation }, i) in publikationen" :key="i">{{ publikation }}</li>
        </ul>
      </section>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'

export default {
  name: 'Publikationen',
  components: {
    VPage,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      rechtsanwaelte: [],
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/rechtsanwaelte`)
    next(vm => {
      vm.rechtsanwaelte = res.data.data
      vm.considerRedirect(to.params.lang)
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/rechtsanwaelte`)
    this.rechtsanwaelte = res.data.data
    this.considerRedirect(to.params.lang)
    next()
  },
  computed: {
    rechtsanwaelteWithPublikationen() {
      return this.rechtsanwaelte?.filter(anwalt => anwalt.publikationen.length)
    },
  },
  methods: {
    considerRedirect(lang = 'de') {
      if (!this.rechtsanwaelteWithPublikationen.length)
        this.$router.push({ name: 'Team', params: { lang } })
    },
  },
}
</script>

<style scoped>
section {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.jump-target {
  position: absolute;
  top: calc(var(--header-height) * -1 - 1em - 32px);
}

li {
  margin-top: 1em;
  white-space: pre-wrap;
}
</style>
