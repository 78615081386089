<template>
  <v-page>
    <main class="honorar">
      <h1 class="page-title">
        {{ lang == 'de' ? 'Honorar / Mandatsvertrag' : 'Fee / Mandate Agreement' }}
      </h1>

      <article class="text hyphenate" v-html="honorar.article"></article>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'
import router from '@/router'

import VPage from '@/components/Page'

export default {
  name: 'Honorar',
  components: {
    VPage,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      honorar: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/honorar`)
    const honorar = res.data.data
    if (honorar.published) {
      next(vm => {
        vm.honorar = honorar
      })
    } else {
      router.push({ name: 'Home', params: { lang: to.params.lang } })
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/honorar`)
    const honorar = res.data.data
    if (honorar.published) {
      this.honorar = res.data.data
      next()
    } else {
      router.push({ name: 'Home', params: { lang: to.params.lang } })
    }
  },
}
</script>

<style scoped>
article {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}
</style>
