<template>
  <v-page>
    <main class="team">
      <h1 class="page-title">{{ lang == 'de' ? 'Das Team' : 'Our Team' }}</h1>

      <template v-if="rechtsanwaelte && rechtsanwaelte.length">
        <div
          class="rechtsanwalt"
          v-for="{
            id,
            name,
            role,
            portrait,
            defaultBild,
            hoverBild,
            schwerpunkte,
            ausbildungen,
            funktionen,
            berufserfahrungen,
            publikationen,
          } in rechtsanwaelte"
          :key="id"
        >
          <div class="portrait">
            <div class="portrait-img">
              <ResponsiveImage
                class="img img--default"
                :urls="defaultBild"
                sizes="(min-width: 1280px) calc((min(calc(100vw - 128px), 1280px) - 96px) / 2), (min-width: 960px) calc((max(calc(75vw + 16px), calc(100vw - 128px)) - 96px) / 2), max(max(calc(75vw + 16px), calc(100vw - 128px)), 400px)"
                :alt="name"
              />
              <ResponsiveImage
                class="img img--hover"
                :urls="hoverBild"
                sizes="(min-width: 1280px) calc((min(calc(100vw - 128px), 1280px) - 96px) / 2), (min-width: 960px) calc((max(calc(75vw + 16px), calc(100vw - 128px)) - 96px) / 2), max(max(calc(75vw + 16px), calc(100vw - 128px)), 400px)"
                :alt="name"
              />
            </div>

            <div class="portrait-text">
              <h2 class="section-title">{{ name }}</h2>
              <p class="rolle">{{ role }}</p>
              <p class="portrait-text text hyphenate">{{ portrait }}</p>
            </div>
          </div>

          <div class="listen">
            <div class="erfahrung">
              <div class="liste ausbildung" v-if="ausbildungen && ausbildungen.length">
                <h3>{{ lang == 'de' ? 'AUSBILDUNG' : 'EDUCATION' }}</h3>
                <ul>
                  <li v-for="({ ausbildung }, i) in ausbildungen" :key="i">{{ ausbildung }}</li>
                </ul>
              </div>

              <div class="liste funktion" v-if="funktionen && funktionen.length">
                <h3>{{ lang == 'de' ? 'FUNKTIONEN' : 'MEMBERSHIPS' }}</h3>
                <ul>
                  <li v-for="({ funktion }, i) in funktionen" :key="i">{{ funktion }}</li>
                </ul>
              </div>

              <div
                class="liste berufserfahrung"
                v-if="berufserfahrungen && berufserfahrungen.length"
              >
                <h3>{{ lang == 'de' ? 'BERUFSERFAHRUNG' : 'PROFESSIONAL EXPERIENCE' }}</h3>
                <ul>
                  <li v-for="({ berufserfahrung }, i) in berufserfahrungen" :key="i">
                    {{ berufserfahrung }}
                  </li>
                </ul>
              </div>

              <div class="publikationen-link">
                <mehr-erfahren-link
                  v-if="publikationen && publikationen.length"
                  :to="{ name: 'Publikationen', hash: `#${id}` }"
                >
                  {{ lang == 'de' ? 'PUBLIKATIONEN' : 'PUBLICATIONS' }}
                </mehr-erfahren-link>
              </div>
            </div>

            <div class="liste taetigkeitsschwerpunkte">
              <h3>{{ lang == 'de' ? 'TÄTIGKEITSSCHWERPUNKTE' : 'AREAS OF PRACTICE' }}</h3>
              <ul>
                <li v-for="({ schwerpunkt }, i) in schwerpunkte" :key="i">
                  {{ schwerpunkt }}
                </li>
              </ul>
            </div>

            <div class="publikationen-link">
              <mehr-erfahren-link
                v-if="publikationen && publikationen.length"
                :to="{ name: 'Publikationen', hash: `#${id}` }"
              >
                {{ lang == 'de' ? 'PUBLIKATIONEN' : 'PUBLICATIONS' }}
              </mehr-erfahren-link>
            </div>
          </div>
        </div>
      </template>

      <template v-if="teammitglieder && teammitglieder.length">
        <div class="teammitglieder">
          <div class="teammitglied" v-for="{ name, role, portrait } in teammitglieder" :key="name">
            <h2 class="section-title">{{ name }}</h2>
            <p class="rolle">{{ role }}</p>
            <div v-if="portrait" class="portrait-text text hyphenate">
              <p>{{ portrait }}</p>
            </div>
          </div>
        </div>
      </template>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'
import MehrErfahrenLink from '@/components/MehrErfahrenLink'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Team',
  components: {
    VPage,
    ResponsiveImage,
    MehrErfahrenLink,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      rechtsanwaelte: [],
      teammitglieder: [],
    }
  },
  async beforeRouteEnter(to, from, next) {
    const [rechtsanwaelteRes, teammitgliederRes] = await Promise.all([
      axios.get(`${to.params.lang}/rechtsanwaelte`),
      axios.get(`${to.params.lang}/teammitglieder`),
    ])
    next(vm => {
      vm.rechtsanwaelte = rechtsanwaelteRes.data.data
      vm.teammitglieder = teammitgliederRes.data.data
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const [rechtsanwaelteRes, teammitgliederRes] = await Promise.all([
      axios.get(`${to.params.lang}/rechtsanwaelte`),
      axios.get(`${to.params.lang}/teammitglieder`),
    ])
    this.rechtsanwaelte = rechtsanwaelteRes.data.data
    this.teammitglieder = teammitgliederRes.data.data
    next()
  },
}
</script>

<style scoped>
.team {
  max-width: 1040px;
}

.section-title {
  margin-bottom: 0;
}

.section-title::after {
  bottom: initial;
  top: -32px;
}

.rolle {
  margin-top: 6px;
  font-size: 20px;
  font-style: italic;
  letter-spacing: 0.02em;
  color: var(--col-primary-dark);
}

@media all and (min-width: 800px) {
  .rolle {
    font-size: 22px;
  }
}

.portrait-text {
  text-align: justify;
}

@media all and (min-width: 800px) {
  .portrait-text {
    line-height: 1.9em;
  }
}

.rechtsanwalt:not(:first-of-type) {
  margin-top: min(160px, 16vh);
}

@media all and (min-width: 960px) {
  .rechtsanwalt .portrait {
    --col-gap: 96px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - var(--col-gap) / 2));
    column-gap: var(--col-gap);
    align-items: center;
  }
}

.rechtsanwalt .portrait-img {
  position: relative;
  width: 100%;
  max-width: 400px;
}

@media all and (min-width: 800px) {
  .rechtsanwalt .portrait-img {
    margin: 0 auto;
  }
}

@media all and (min-width: 960px) {
  .rechtsanwalt .portrait-img {
    max-width: none;
  }
}

.rechtsanwalt .portrait-img::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}

.rechtsanwalt .img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
  transition: opacity 250ms cubic-bezier(0, 0.25, 0.75, 1);
}

.rechtsanwalt .img--hover {
  opacity: 0;
}

.rechtsanwalt .portrait-img:hover .img--default {
  opacity: 0;
}

.rechtsanwalt .portrait-img:hover .img--hover {
  opacity: 1;
}

.rechtsanwalt .section-title {
  margin-top: calc(32px + min(96px, 10vh));
}

@media all and (min-width: 800px) {
  .rechtsanwalt .section-title {
    text-align: center;
  }

  .rechtsanwalt .section-title::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media all and (min-width: 960px) {
  .rechtsanwalt .section-title {
    margin-top: 0;
    text-align: left;
  }

  .rechtsanwalt .section-title::after {
    left: 0;
    transform: none;
  }
}

@media all and (min-width: 800px) {
  .rechtsanwalt .rolle {
    text-align: center;
  }
}

@media all and (min-width: 960px) {
  .rechtsanwalt .rolle {
    text-align: left;
  }
}

.rechtsanwalt .portrait-text {
  margin-top: 56px;
}

@media all and (min-width: 800px) {
  .rechtsanwalt .portrait-text {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (min-width: 800px) {
  .listen {
    --col-gap: 96px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - var(--col-gap) / 2));
    column-gap: var(--col-gap);
  }
}

@media all and (min-width: 800px) {
  .listen {
    margin-top: 24px;
  }
}

.rechtsanwalt .liste {
  max-width: 440px;
  margin-top: min(56px, calc(28px + 3vh));
}

@media all and (min-width: 960px) {
  .taetigkeitsschwerpunkte {
    order: -1;
  }
}

.rechtsanwalt h3 {
  font-weight: var(--weight-semibold);
  letter-spacing: 0.1em;
}

@media all and (min-width: 960px) {
  .rechtsanwalt h3 {
    font-size: 18px;
  }
}

.rechtsanwalt .listen ul {
  margin-top: 24px;
}

.rechtsanwalt .listen li {
  margin-top: 0.7em;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: 0.02em;
}

@media all and (min-width: 960px) {
  .rechtsanwalt .listen li {
    font-size: 18px;
  }
}

.taetigkeitsschwerpunkte ul {
  font-style: italic;
}

.erfahrung > .publikationen-link {
  display: none;
}

@media all and (min-width: 800px) {
  .erfahrung > .publikationen-link {
    display: block;
  }

  .listen > .publikationen-link {
    display: none;
  }
}

@media all and (min-width: 800px) {
  .teammitglieder {
    --col-gap: 96px;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - var(--col-gap) / 2));
    column-gap: var(--col-gap);
  }
}

.teammitglied {
  margin-top: min(160px, 16vh);
}

.teammitglied .section-title {
  margin-top: 0;
}

.teammitglied .portrait-text {
  margin-top: 36px;
  font-size: 16px;
  text-align: left;
}

@media all and (min-width: 800px) {
  .teammitglied .portrait-text {
    max-width: 440px;
  }
}

@media all and (min-width: 960px) {
  .teammitglied .portrait-text {
    font-size: 18px;
  }
}
</style>
