<template>
  <div class="menu">
    <nav>
      <ul>
        <li>
          <router-link
            class="route-link"
            :class="{ active: $route.name == 'Kanzlei' }"
            :to="{ name: 'Kanzlei' }"
          >
            {{ lang == 'de' ? 'KANZLEI' : 'ABOUT' }}
          </router-link>
        </li>
        <li>
          <router-link
            class="route-link"
            :class="{ active: $route.name == 'Fachgebiete' || $route.name == 'Fachgebiet' }"
            :to="{ name: 'Fachgebiete' }"
          >
            {{ lang == 'de' ? 'FACHGEBIETE' : 'EXPERTISE' }}
          </router-link>
        </li>
        <li>
          <router-link
            class="route-link"
            :class="{ active: $route.name == 'Team' }"
            :to="{ name: 'Team' }"
          >
            TEAM
          </router-link>
        </li>
        <li v-if="honorar.published">
          <router-link
            class="route-link"
            :class="{ active: $route.name == 'Honorar' }"
            :to="{ name: 'Honorar' }"
          >
            {{ lang == 'de' ? 'HONORAR' : 'MANDATE & FEE' }}
          </router-link>
        </li>
        <li>
          <router-link
            class="route-link"
            :class="{ active: $route.name == 'Kontakt' }"
            :to="{ name: 'Kontakt' }"
          >
            {{ lang == 'de' ? 'KONTAKT' : 'CONTACT' }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MenuDesktop',
  data() {
    return {
      honorar: {},
    }
  },
  created() {
    axios.get(`${this.lang}/honorar`).then(response => (this.honorar = response.data.data))
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
  watch: {
    $route() {
      axios.get(`${this.lang}/honorar`).then(response => (this.honorar = response.data.data))
    },
  },
}
</script>

<style scoped>
.menu {
  position: relative;
}

nav {
  margin-right: -1.5em;
}

ul {
  display: flex;
}

.route-link {
  display: block;
  position: relative;
  padding: 1em 1.2em 1em 1.5em;
  font-family: var(--font-condensed);
  font-size: 18px;
  letter-spacing: 0.3em;
}

.route-link::after {
  content: '';
  position: absolute;
  width: calc(100% - 1.5em - 1.5em);
  left: 1.5em;
  bottom: calc(1em / 3);
  border-bottom: 2px solid var(--col-primary);
  opacity: 0;
  transform: translate3d(0, -4px, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1), opacity 250ms linear;
}

.route-link.active::after,
.route-link:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
