<template>
  <v-page>
    <main class="fachgebiete-liste">
      <h1 class="page-title">{{ lang == 'de' ? 'Fachgebiete' : 'Expertise' }}</h1>

      <fachgebiete-uebersicht-liste :fachgebiete="fachgebiete"></fachgebiete-uebersicht-liste>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'
import FachgebieteUebersichtListe from '@/components/FachgebieteUebersichtListe'

export default {
  name: 'FachgebieteListe',
  components: {
    VPage,
    FachgebieteUebersichtListe,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      fachgebiete: [],
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/fachgebiete`)
    next(vm => (vm.fachgebiete = res.data.data))
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/fachgebiete`)
    this.fachgebiete = res.data.data
    next()
  },
}
</script>
