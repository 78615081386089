<template>
  <div id="app">
    <transition name="page" appear mode="out-in" @after-leave="$root.$emit('triggerScroll')">
      <!--
        key "$route.name" is selected to fulfill two conditions
        1. show animation when changing route (by forcing fresh component)
        2. grab fresh data when changing language (by keeping component alive - no animation in this case)
        -->
      <router-view :key="$route.name"></router-view>
    </transition>

    <language-toggle class="language-toggle"></language-toggle>

    <v-header></v-header>
  </div>
</template>

<script>
import VHeader from '@/components/Header'
import LanguageToggle from '@/components/LanguageToggle'

export default {
  components: {
    VHeader,
    LanguageToggle,
  },
}
</script>

<style scoped>
.language-toggle {
  display: none;
  position: fixed;
  right: 48px;
  bottom: 24px;
}

@media all and (min-width: 1280px) {
  .language-toggle {
    display: block;
  }
}

.page-enter {
  transform: translate3d(0, 24px, 0);
  opacity: 0;
}

.page-enter-active {
  transition: transform 1000ms cubic-bezier(0, 0.5, 0.5, 1),
    opacity 1000ms cubic-bezier(0.5, 0, 0.5, 1);
}

.page-leave-to {
  opacity: 0;
}

.page-leave-active {
  transition: opacity 375ms linear;
}
</style>
