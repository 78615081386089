<template>
  <v-page>
    <main class="fachgebiet">
      <div class="back-to-fachgebiete">
        <router-link class="back-to-fachgebiete-link" :to="{ name: 'Fachgebiete' }">
          <span class="arrow-left"></span>
          <span>{{ lang == 'de' ? 'FACHGEBIETE' : 'EXPERTISE' }}</span>
        </router-link>
      </div>

      <fachgebiete-menu
        class="fachgebiete-liste"
        :fachgebiete="fachgebieteWithDetailsPage"
      ></fachgebiete-menu>

      <transition name="page" appear mode="out-in" @after-leave="$root.$emit('triggerScroll')">
        <router-view :key="`${$route.fullpath}`"></router-view>
      </transition>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'
import FachgebieteMenu from '@/components/FachgebieteMenu'

export default {
  name: 'Fachgebiete',
  components: {
    VPage,
    FachgebieteMenu,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      fachgebiete: [],
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/fachgebiete`)
    next(vm => (vm.fachgebiete = res.data.data))
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/fachgebiete`)
    this.fachgebiete = res.data.data
    next()
  },
  computed: {
    fachgebieteWithDetailsPage: vm =>
      vm.fachgebiete.filter(fachgebiet => fachgebiet.hasDetailsPage),
  },
}
</script>

<style scoped>
.fachgebiet {
  padding-top: calc(var(--content-top) + 3em);
}

@media not all and (min-width: 960px) {
  .fachgebiet {
    max-width: 640px;
    margin: 0 auto;
  }
}

@media all and (min-width: 960px) {
  .fachgebiet {
    display: grid;
    grid-template-columns: max(400px, calc(100% / 3)) min(640px, calc(100% / 3 * 2 - 80px));
    padding-top: var(--content-top);
  }
}

.back-to-fachgebiete {
  position: fixed;
  width: 100%;
  top: var(--header-height);
  left: 0;
  align-items: center;
  font-weight: var(--weight-semibold);
  letter-spacing: 0.1em;
  z-index: 200;
  background: var(--col-white);
}

@media all and (min-width: 960px) {
  .back-to-fachgebiete {
    display: none;
  }
}

.back-to-fachgebiete-link {
  display: flex;
  width: var(--content-width);
  margin: 0 auto;
  padding: 1em 0;
}

.back-to-fachgebiete .arrow-left {
  position: relative;
  height: 1em;
  width: 1em;
  margin-right: 1em;
}

.arrow-left::before,
.arrow-left::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: var(--col-black);
}

.arrow-left::before {
  top: calc(0.5em + 1px);
  transform-origin: top left;
  transform: rotate(-45deg);
}

.arrow-left::after {
  top: calc(0.5em - 1px);
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.fachgebiete-liste {
  display: none;
}

@media all and (min-width: 960px) {
  .fachgebiete-liste {
    display: block;
  }
}

.page-enter {
  transform: translate3d(0, 24px, 0);
  opacity: 0;
}

.page-enter-active {
  transition: transform 1000ms cubic-bezier(0, 0.5, 0.5, 1),
    opacity 1000ms cubic-bezier(0.5, 0, 0.5, 1);
}

.page-leave-to {
  opacity: 0;
}

.page-leave-active {
  transition: opacity 375ms linear;
}
</style>
