<template>
  <v-page>
    <main class="datenschutz">
      <h1 class="page-title hyphenate">
        {{ lang == 'de' ? 'Datenschutzerklärung' : 'Privacy Policy' }}
      </h1>

      <article class="text hyphenate" v-html="datenschutz.article"></article>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'

export default {
  name: 'Datenschutz',
  components: {
    VPage,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      datenschutz: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/datenschutz`)
    next(vm => (vm.datenschutz = res.data.data))
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/datenschutz`)
    this.datenschutz = res.data.data
    next()
  },
}
</script>

<style scoped>
article {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}
</style>
