import Vue from 'vue'
import VueRouter from 'vue-router'

import Language from '../views/Language.vue'

import Home from '../views/Home.vue'
import Kanzlei from '../views/Kanzlei.vue'
import FachgebieteListe from '../views/FachgebieteListe.vue'
import Fachgebiete from '../views/Fachgebiete.vue'
import Fachgebiet from '../views/Fachgebiete/Fachgebiet.vue'
import Team from '../views/Team.vue'
import Publikationen from '../views/Publikationen.vue'
import Honorar from '../views/Honorar.vue'
import Kontakt from '../views/Kontakt.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'

import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home',
      params: { lang: 'de' },
    },
  },
  {
    path: '/kanzlei',
    redirect: {
      name: 'Kanzlei',
      params: { lang: 'de' },
    },
  },
  {
    path: '/fachgebiete',
    redirect: {
      name: 'Fachgebiete',
      params: { lang: 'de' },
    },
  },
  {
    path: '/team',
    redirect: {
      name: 'Team',
      params: { lang: 'de' },
    },
  },
  {
    path: '/publikationen',
    redirect: {
      name: 'Publikationen',
      params: { lang: 'de' },
    },
  },
  {
    path: '/honorar',
    redirect: {
      name: 'Honorar',
      params: { lang: 'de' },
    },
  },
  {
    path: '/kontakt',
    redirect: {
      name: 'Kontakt',
      params: { lang: 'de' },
    },
  },
  {
    path: '/impressum',
    redirect: {
      name: 'Impressum',
      params: { lang: 'de' },
    },
  },
  {
    path: '/datenschutz',
    redirect: {
      name: 'Datenschutz',
      params: { lang: 'de' },
    },
  },
  {
    path: '/:lang',
    component: Language,
    beforeEnter: (to, from, next) => {
      const languages = ['de', 'en']
      if (languages.includes(to.params.lang)) next()
      else next({ name: 'NotFound' })
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        props: true,
      },
      {
        path: 'kanzlei',
        name: 'Kanzlei',
        component: Kanzlei,
        props: true,
      },
      {
        path: 'fachgebiete',
        name: 'Fachgebiete',
        component: FachgebieteListe,
        props: true,
      },
      {
        path: 'fachgebiete',
        component: Fachgebiete,
        props: true,
        children: [
          {
            path: ':fachgebietSlug',
            name: 'Fachgebiet',
            component: Fachgebiet,
            props: true,
          },
        ],
      },
      {
        path: 'team',
        name: 'Team',
        component: Team,
        props: true,
      },
      {
        path: 'publikationen',
        name: 'Publikationen',
        component: Publikationen,
        props: true,
      },
      {
        path: 'honorar',
        name: 'Honorar',
        component: Honorar,
        props: true,
      },
      {
        path: 'kontakt',
        name: 'Kontakt',
        component: Kontakt,
        props: true,
      },
      {
        path: 'impressum',
        name: 'Impressum',
        component: Impressum,
        props: true,
      },
      {
        path: 'datenschutz',
        name: 'Datenschutz',
        component: Datenschutz,
        props: true,
      },
    ],
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) =>
    // this makes sure, pages don't jump to top before fading out
    // this only works in conjunction with setting @after-leave="$root.$emit('triggerScroll')"
    //   on the transition element
    new Promise(resolve => {
      const position = savedPosition || { x: 0, y: 0 }
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash
        }
        if (to.matched.some(m => m.meta.scrollToTop)) {
          position.x = 0
          position.y = 0
        }
      }
      router.app.$root.$once('triggerScroll', () => {
        router.app.$nextTick(() => resolve(position))
      })
    }),
})

router.beforeEach((to, from, next) => {
  // remove previous canonical-tag if exists
  document.head.querySelector('link[rel=canonical]')?.remove()

  // add new canonical-tag if not on canonical (=german) page
  if (to.params.lang == 'en') {
    const canonicalHref = 'https://ra-krist.at' + to.fullPath.replace('/en/', '/de/')
    const tag = document.createElement('link')
    tag.setAttribute('rel', 'canonical')
    tag.setAttribute('href', canonicalHref)
    document.head.appendChild(tag)
  }

  next()
})

export default router
