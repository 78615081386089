<template>
  <router-link class="mehr-erfahren" :to="to">
    <span><slot></slot></span>
    <arrow-right class="arrow"></arrow-right>
  </router-link>
</template>

<script>
import ArrowRight from '@/components/ArrowRight'

export default {
  name: 'MehrErfahrenLink',
  components: {
    ArrowRight,
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>

<style scoped>
.mehr-erfahren {
  display: flex;
  align-items: center;
  margin-top: min(48px, 6vh);
}

.mehr-erfahren span {
  padding-bottom: 0.1em;
  font-size: var(--font-size-regular);
  font-family: var(--font-condensed);
  line-height: 1.2em;
  letter-spacing: 0.2em;
}

.arrow {
  height: 14px;
  margin-left: 28px;
  transform: translate3d(0, 0, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1);
}

.mehr-erfahren:hover .arrow {
  transform: translate3d(14px, 0, 0);
}
</style>
