<template>
  <section v-if="fachgebiet" class="content">
    <h1 class="section-title" :class="{ hyphenate: hyphenateTitle }">
      {{ fachgebiet.title ? fachgebiet.title.replace('ß', 'ss') : '' }}
    </h1>

    <p class="text hyphenate show-line-breaks">{{ fachgebiet.introduction }}</p>

    <div v-if="fachgebiet.bild" class="img-wrap">
      <ResponsiveImage
        class="img"
        :urls="fachgebiet.bild"
        sizes="(min-width: 1280px) min(calc(min(calc(100vw - 128px), 1280px) / 3 * 2 - 80px), 640px), (min-width: 960px) min(calc(max(calc(75vw + 16px), calc(100vw - 128px)) / 3 * 2 - 80px), 640px), min(max(calc(75vw + 16px), calc(100vw - 128px)), 640px)"
        :alt="fachgebiet.title"
      />
    </div>

    <div
      v-if="fachgebiet.kernkompetenzen && fachgebiet.kernkompetenzen.length"
      class="kernkompetenzen text hyphenate"
    >
      <p>
        {{
          lang == 'de'
            ? 'Zu unseren Kernkompetenzen in diesem Fachgebiet zählen:'
            : 'Our core competencies in this field include:'
        }}
      </p>
      <ul>
        <li v-for="(kernkompetenz, i) in fachgebiet.kernkompetenzen" :key="i">
          {{ kernkompetenz.kompetenz }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import router from '@/router'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Fachgebiet',
  components: {
    ResponsiveImage,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
    fachgebietSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fachgebiet: {},
      hyphenateTitle: true,
    }
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const res = await axios.get(
        `${to.params.lang}/fachgebiet-by-slug/${to.params.fachgebietSlug}`
      )
      next(vm => {
        vm.fachgebiet = res.data.data
      })
    } catch {
      router.push({ name: 'Fachgebiete', params: { lang: to.params.lang } })
    }
  },
  async beforeRouteUpdate(to, from, next) {
    document.getElementById('app').scrollIntoView()
    try {
      const res = await axios.get(
        `${to.params.lang}/fachgebiet-by-slug/${to.params.fachgebietSlug}`
      )
      this.fachgebiet = res.data.data
      next()
    } catch {
      router.push({ name: 'Fachgebiete', params: { lang: to.params.lang } })
    }
  },
  created() {
    this.considerTitleHyphenation()
    window.addEventListener('resize', this.considerTitleHyphenation)
  },
  methods: {
    considerTitleHyphenation() {
      this.hyphenateTitle = window.innerWidth < 512
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.considerTitleHyphenation)
  },
}
</script>

<style scoped>
.content {
  position: sticky;
}

.img-wrap {
  position: relative;
  margin-top: min(48px, 6vh);
  padding-bottom: 50%;
}

.img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.kernkompetenzen {
  margin-top: min(48px, 6vh);
}
</style>
