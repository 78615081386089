<template>
  <div class="page">
    <div class="page-content">
      <slot></slot>
    </div>

    <v-footer></v-footer>
  </div>
</template>

<script>
import VFooter from '@/components/Footer'
export default {
  name: 'Page',
  components: {
    VFooter,
  },
}
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex: 1;
}
</style>
