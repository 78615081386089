<template>
  <v-page>
    <main class="kanzlei">
      <h1 class="page-title">{{ lang == 'de' ? 'Die Kanzlei' : 'About' }}</h1>

      <article class="text hyphenate show-line-breaks" v-html="kanzlei.article"></article>

      <div class="img-wrap">
        <ResponsiveImage
          v-if="kanzlei.bild"
          class="img"
          :urls="kanzlei.bild"
          sizes="(min-width: 928px) 800px, max(calc(75vw + 16px), calc(100vw - 128px))"
          :alt="lang == 'de' ? 'Kanzlei' : 'interior'"
        />
      </div>
    </main>
  </v-page>
</template>

<script>
import axios from 'axios'

import VPage from '@/components/Page'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default {
  name: 'Kanzlei',
  components: {
    VPage,
    ResponsiveImage,
  },
  props: {
    lang: {
      type: String,
      default: 'de',
    },
  },
  data() {
    return {
      kanzlei: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get(`${to.params.lang}/kanzlei`)
    next(vm => (vm.kanzlei = res.data.data))
  },
  async beforeRouteUpdate(to, from, next) {
    const res = await axios.get(`${to.params.lang}/kanzlei`)
    this.kanzlei = res.data.data
    next()
  },
}
</script>

<style scoped>
article {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.img-wrap {
  position: relative;
  width: 100vw;
  max-width: 800px;
  margin: min(96px, 12vh) auto 0;
  left: 50%;
  transform: translateX(-50%);
}

@media all and (min-width: 560px) {
  .img-wrap {
    width: 100%;
    left: initial;
    transform: none;
  }
}

.img-wrap::before {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 3 * 2);
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
}
</style>
