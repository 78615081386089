<template>
  <div class="map">
    <img :src="require('@/assets/img/lageplan.svg')" alt="Lageplan" />
    <a
      class="google"
      href="//www.google.com/maps/place/Liebiggasse+4,+1010+Wien"
      target="_blank"
      ref="nofollow"
    >
      <span class="google-icon">
        <img :src="require('@/assets/img/google_maps_icon.svg')" alt="Google Maps Symbol" />
      </span>
      <span class="google-text">
        {{ lang == 'de' ? 'In Google Maps ansehen' : 'View in Google Maps' }}
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Lageplan',
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
}
</script>

<style scoped>
.map {
  position: relative;
}

.google {
  --google-padding: 8px;
  --icon-size: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  height: calc(var(--icon-size) + 2 * var(--google-padding));
  bottom: 16px;
  right: 16px;
  padding: var(--google-padding);
  border-radius: 4px;
  background: var(--col-white);
  box-shadow: 2px 3px 15px 0 rgba(0, 0, 0, 0.075);
  overflow: hidden;
}

.google-icon {
  display: block;
  height: var(--icon-size);
  width: var(--icon-size);
  margin-right: 8px;
}

.google-icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.google-text {
  width: 0;
  margin-right: -8px;
  font-size: var(--font-size-regular);
  font-weight: var(--weight-sans-regular);
  line-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: width 375ms cubic-bezier(0, 0.5, 0.5, 1), opacity 375ms linear;
}

.map:hover .google-text {
  width: calc(12.5 * var(--font-size-regular));
  opacity: 1;
}
</style>
