<template>
  <v-page>
    <main class="impressum">
      <h1 class="page-title">Impressum</h1>
      <p class="offenlegung">Offenlegung gemäß § 5 E-Commerce-Gesetz</p>

      <div class="text">
        <address class="section anschrift">
          <h3>Krist Rechtsanwälte</h3>
          <div>
            <p>Dr. Josef Krist</p>
            <p>Dr. Andreas Krist LL.M.</p>
          </div>
          <div class="anschrift">
            <p>Liebiggasse 4</p>
            <p>1010 Wien</p>
          </div>

          <div class="telefon">
            <p class="flex">
              <span>Tel</span>
              <span>
                +43 (1) 40 80 625
                <br />
                +43 (1) 40 80 626
              </span>
            </p>
          </div>

          <div class="fax">
            <p class="flex">
              <span>Fax</span>
              <span>+43 (1) 40 80 625 20</span>
            </p>
          </div>

          <div class="email-und-web">
            <p>email: office@ra-krist.at</p>
            <p>www.ra-krist.at</p>
          </div>
        </address>

        <div class="anwaelte">
          <div class="section josef-krist">
            <h3>Dr. Josef Krist</h3>
            <div>
              <p class="flex">
                <span>RA-Code:</span>
                <span>R110583</span>
              </p>
              <p class="flex">
                <span>UID:</span>
                <span>ATU19025800</span>
              </p>
            </div>
          </div>

          <div class="section andreas-krist">
            <h3>Dr. Andreas Krist LL.M.</h3>
            <div>
              <p class="flex">
                <span>RA-Code:</span>
                <span>R119704</span>
              </p>
              <p class="flex">
                <span>UID:</span>
                <span>ATU76355356</span>
              </p>
            </div>
          </div>
        </div>

        <div class="section bankverbindung">
          <h3>Bankverbindung</h3>
          <div class="fremdgeld">
            <p>Fremdgeld</p>
            <p class="flex">
              <span>IBAN:</span>
              <span>AT413295100000716076</span>
            </p>
            <p class="flex">
              <span>BIC:</span>
              <span>RLNWATWWWDF</span>
            </p>
          </div>
          <div class="honorar">
            <p>Honorar</p>
            <p class="flex">
              <span>IBAN:</span>
              <span>AT273295100000708180</span>
            </p>
            <p class="flex">
              <span>BIC:</span>
              <span>RLNWATWWWDF</span>
            </p>
          </div>
        </div>

        <div class="section kammer">
          <h3>Kammerzugehörigkeit</h3>
          <div>
            <!-- prettier-ignore -->
            <p>Als selbstständige Rechtsanwälte nach österreichischem Recht unterliegen Dr. Josef Krist und Dr. Andreas Krist LL.M. dem Standesrecht für Rechtsanwälte unter Aufsicht der <a href="//www.rakwien.at/" target="_blank" ref="nofollow">Rechtsanwaltskammer Wien</a>.</p>
          </div>
          <div>
            <p>
              Die Rechtsanwaltsordnung (RAO) kann unter
              <a href="//www.ris.bka.gv.at" target="_blank" ref="nofollow">www.ris.bka.gv.at</a>
              sowie unter
              <a href="//www.oerak.at" target="_blank" ref="nofollow">www.oerak.at</a>
              abgerufen werden.
            </p>
          </div>
        </div>

        <div class="section datenschutz">
          <h3>Datenschutz</h3>
          <div>
            <!-- prettier-ignore -->
            <p>Unsere Datenschutzerklärung finden sie <router-link :to="{ name: 'Datenschutz' }">hier</router-link>.</p>
          </div>
        </div>
      </div>
    </main>
  </v-page>
</template>

<script>
import VPage from '@/components/Page'

export default {
  name: 'Impressum',
  components: {
    VPage,
  },
}
</script>

<style scoped>
.impressum {
  max-width: 720px;
}

.offenlegung {
  margin: -2em 0 96px;
  font-size: 18px;
  line-height: 1.2em;
  font-style: italic;
  text-align: center;
}

@media all and (min-width: 640px) {
  .offenlegung {
    font-size: 20px;
  }
}

.section {
  margin-top: 3.2em;
}

.section > div {
  margin-top: 0.8em;
}

.impressum h3 {
  margin: 0;
}

.impressum .text {
  font-size: var(--font-size-reduced);
}

@media all and (min-width: 640px) {
  .impressum .text {
    font-size: var(--font-size-regular);
  }
}

.flex {
  display: flex;
}

.telefon .flex span:first-child,
.fax .flex span:first-child {
  width: 3em;
}

.anwaelte .flex span:first-child {
  width: 5.5em;
}

@media all and (min-width: 720px) {
  .anwaelte {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.bankverbindung .flex span:first-child {
  width: 4em;
}

@media all and (min-width: 720px) {
  .bankverbindung {
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .bankverbindung h3 {
    grid-column: span 2;
  }
}
</style>
