<template>
  <ul>
    <li v-for="({ slug, title, hasDetailsPage }, i) in fachgebiete" :key="i">
      <router-link
        class="link"
        :class="{ inactive: !hasDetailsPage }"
        :to="{ name: 'Fachgebiet', params: { fachgebietSlug: slug } }"
      >
        <span>{{ title.replace('ß', 'ss') }}</span>
        <arrow-right v-if="hasDetailsPage" class="arrow"></arrow-right>
      </router-link>
    </li>
  </ul>
</template>

<script>
import ArrowRight from '@/components/ArrowRight'

export default {
  name: 'FachgebieteUebersichtListe',
  components: {
    ArrowRight,
  },
  props: {
    fachgebiete: {
      type: Array,
      required: true,
    },
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
}
</script>

<style scoped>
@media all and (min-width: 720px) {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: min(10vw, 176px);
  }
}

li {
  display: flex;
  align-items: center;
  font-size: var(--font-size-regular);
  letter-spacing: 0.02em;
}

@media not all and (min-width: 720px) {
  li:not(:last-child) {
    border-bottom: 1px solid var(--col-primary);
  }
}

@media all and (min-width: 720px) {
  li:not(:last-child):not(:nth-last-child(2)) {
    border-bottom: 1px solid var(--col-primary);
  }
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.625em 0 0.75em;
  line-height: 1.2em;
}

@media all and (min-width: 480px) {
  .link {
    padding: 0.8375em 0 0.9625em;
  }
}

.link.inactive {
  pointer-events: none;
}

.arrow {
  flex-shrink: 0;
  height: 14px;
  margin-left: 28px;
  transform: translate3d(0, 0, 0);
  transition: transform 250ms cubic-bezier(0, 0.5, 0.5, 1);
}

.link:hover .arrow {
  transform: translate3d(14px, 0, 0);
}
</style>
