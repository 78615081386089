<template>
  <div class="fachgebiete-menu">
    <div class="list-wrap">
      <ul>
        <li v-for="{ title, slug } in fachgebiete" :key="slug">
          <router-link class="link" :to="{ name: 'Fachgebiet', params: { fachgebietSlug: slug } }">
            {{ title.replace('ß', 'ss') }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FachgebieteMenu',
  props: {
    fachgebiete: {
      type: Array,
      required: true,
    },
  },
  computed: {
    lang: vm => vm.$route.params.lang || 'de',
  },
}
</script>

<style scoped>
.fachgebiete-menu {
  height: 100%;
}

.list-wrap {
  position: sticky;
  top: var(--content-top);
}

ul {
  width: 100%;
}

li {
  width: 320px;
}

li:not(:last-child) {
  border-bottom: 1px solid var(--col-primary);
}

.link {
  display: block;
  padding: 0.375em 0 0.5em;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.2em;
}
</style>
