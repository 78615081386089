<template>
  <svg viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.00008H30M30 8.00008L24.3243 1M30 8.00008L24.3243 15.0001" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight',
}
</script>

<style scoped>
svg {
  height: 100%;
}

path {
  stroke: var(--col-black);
}
</style>
