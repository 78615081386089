<template>
  <header>
    <div class="inner-wrap">
      <router-link :to="{ name: 'Home' }">
        <v-logo class="logo"></v-logo>
      </router-link>

      <menu-desktop class="menu-desktop"></menu-desktop>
      <menu-mobile class="menu-mobile"></menu-mobile>
    </div>
  </header>
</template>

<script>
import MenuDesktop from '@/components/MenuDesktop'
import MenuMobile from '@/components/MenuMobile'
import VLogo from '@/components/Logo'

export default {
  name: 'Header',
  components: {
    MenuDesktop,
    MenuMobile,
    VLogo,
  },
}
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--col-white);
  z-index: 500;
}

.inner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--content-width);
  height: var(--header-height);
  margin: 0 auto;
}

.logo {
  height: min(calc(15px + 5vw), 45px);
}

.menu-desktop {
  display: none;
}

@media all and (min-width: 1280px) {
  .menu-mobile {
    display: none;
  }

  .menu-desktop {
    display: block;
  }
}
</style>
